import React, { useState } from 'react';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Sidebar from '../../Layout/Header/Sidebar';
import Header from '../../Layout/Header/Header';
import { useNavigate } from 'react-router-dom';

const UploadGallery = ({ onUpload }) => {
    const [fileList, setFileList] = useState([]);
    const navigate = useNavigate();

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleUpload = () => {
        onUpload(fileList);
    };


    return (
        <>
            <Sidebar />
            <Header />
            <div className="main-wrapper">
                <div className="upload-section">
                    <div className="upload-file-content">
                        <Upload.Dragger
                            fileList={fileList}
                            onChange={handleFileChange}
                            beforeUpload={() => false}
                            className="upload-list-inline"
                            style={{ border: '1px dashed #ce1b28', padding: '20px', borderRadius: '8px' }}
                        >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined style={{ color: "#ce1b28" }} />
                            </p>
                            <p className="ant-upload-text">JPG, PNG, ZIP files</p>
                            <p className="ant-upload-hint">* select a file or drag and drop it here</p>
                        </Upload.Dragger>

                        <div className="d-flex justify-content-center mt-5">
                            <button className='me-2 cancel-btn' onClick={handleCancel}>Cancel</button>
                            <button className='start-upload-btn' onClick={handleUpload}>Start Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadGallery;
