import React from "react";
import "./gallery.css";
import { useNavigate } from "react-router-dom";
import GalleryList from "./GalleryList";

const Gallery = () => {

    const navigate = useNavigate();

    const handleUpload = () => {
        localStorage.setItem("uploadphotos", true)
        navigate("/upload-photos")
    };

    return (
        <>
            <div className="main-title-all">
                <span>All Photos</span>
                {/* <button onClick={handleUpload}>Upload</button> */}
            </div>

            <GalleryList />
        </>
    );
};

export default Gallery;
