import React, { useEffect, useState, useRef, useMemo } from "react";
import { Input, DatePicker, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ArrowRightOutlined } from "@ant-design/icons";
import Select from "react-select";
import Sidebar from "../../Layout/Header/Sidebar";
import Header from "../../Layout/Header/Header";
import Instance from "../../AxiosConfig";
import "./jobs.css";
import { useNavigate, useParams } from "react-router-dom";
import stateCityOptions from "../../jsons/statesCity";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaTrash } from "react-icons/fa6";
import moment from "moment/moment";
import dayjs from "dayjs";
import { showSuccessAlert, showErrorAlert } from "../../globalConstant";

const JobCategoryOptions = [
  { value: "Allied Jobs", label: "Allied Jobs" },
  { value: "Nursing Jobs", label: "Nursing Jobs" },
];

const JobTypeOptions = [
  { value: "Part Time", label: "Part Time" },
  { value: "Full Time", label: "Full Time" },
  { value: 'Travel Contact', label: 'Travel Contact' },
  { value: 'Per Diem', label: 'Per Diem' },
  { value: "Internship", label: "Internship" },
  { value: "Contract", label: "Contract" },
];

const JobStatusOption = [
  { value: "Open", label: "Open" },
  { value: "Closed", label: "Closed" },
];

const ShiftTypeOptions = [
  { value: "Day", label: "Day Shift" },
  { value: "Mid", label: "Mid Shift" },
  { value: "Evening", label: "Evening Shift" },
  { value: "Night", label: "Night Shift" },
  { value: "Rotating", label: "Rotating" },
];

const ShiftHoursOptions = [
  { value: "5x8", label: "5x8" },
  { value: "4x10", label: "4x10" },
  { value: "4x12", label: "4x12" },
  { value: "3x12", label: "3x12" }
];

const TimeZoneOptions = [
  {
    label: "Hawaii–Aleutian Standard Time",
    value: "Hawaii-Aleutian Standard Time",
  },
  { label: "Alaska Standard Time", value: "Alaska Standard Time" },
  { label: "Mountain Standard Time", value: "Mountain Standard Time" },
  { label: "Eastern Standard Time", value: "Eastern Standard Time" },
  { label: "Central Standard Time", value: "Central Standard Time" },
  { label: "Pacific Standard Time", value: "Pacific Standard Time" },
];

const EditJob = () => {
  const quillRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const stateOptions = stateCityOptions?.states?.map((item) => ({
    label: item.name,
    value: item,
  }));

  const cityOptions = useMemo(() => {
    if (!formData?.state) return;
    return formData?.state?.value?.cities?.map((item) => ({
      label: item?.name,
      value: item,
    }));
  }, [formData?.state]);

  const fetchJobData = async () => {
    try {
      setIsLoading(true);
      const url = `/getJobById/${id}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      });
      if (response.status === 200 && response?.data?.job) {
        let data = { ...response?.data?.job };
        data["jobCategory"] = {
          label: data["jobCategory"],
          value: data["jobCategory"],
        };
        if (data["payBasis"]) {
          data["payBasis"] = {
            label: data["payBasis"],
            value: data["payBasis"],
          };
        }
        data["jobType"] = data["jobType"]?.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        data["shiftType"] = data['shiftType'] ? {
          label: data["shiftType"],
          value: data["shiftType"],
        } : null;

        data["shiftHours"] = data["shiftHours"] ? {
          label: data["shiftHours"],
          value: data["shiftHours"],
        } : null;

        let stateData = stateCityOptions?.states?.find(
          (item) => item.name === data["state"]
        );
        if (stateData) {
          data["state"] = {
            label: data["state"],
            value: stateData,
          };
          let cities = stateData?.cities?.map((item) => ({
            label: item?.name,
            value: item,
          }));
          cities = cities?.filter((item) =>
            data["cities"]?.includes(item.label)
          );
          data["cities"] = cities;
        }
        data["timeZones"] = data["timeZones"]?.map((item) => ({
          label: item,
          value: item,
        }));
        data["jobStatus"] = {
          label: data["jobStatus"],
          value: data["jobStatus"],
        };
        data["jobPostedDate"] = moment(data["jobPostedDate"]);
        if (data["jobStartDate"]) data["jobStartDate"] = moment(data["jobStartDate"]);
        setFormData(data);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || "An error occured");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  const handleAddTagsKeyword = () => {
    let newData = { ...formData };
    newData["tagsKeyword"] = [...(newData["tagsKeyword"] || []), ""];
    setFormData(newData);
  };

  const handleAddJobBenefit = () => {
    let newData = { ...formData };
    newData["jobBenefits"] = [...(newData["jobBenefits"] || []), ""];
    setFormData(newData);
  };

  const handleAddLicense = () => {
    let newData = { ...formData };
    newData["license"] = [...(newData["license"] || []), ""];
    setFormData(newData);
  };

  const handleAddCertification = () => {
    let newData = { ...formData };
    newData["certification"] = [...(newData["certification"] || []), ""];
    setFormData(newData);
  };

  const handleCertificationChange = (index, value) => {
    let newData = { ...formData };
    newData["certification"][index] = value;
    setFormData(newData);
  }

  const handleAddImmunization = () => {
    let newData = { ...formData };
    newData["immunization"] = [...(newData["immunization"] || []), ""];
    setFormData(newData);
  };

  const handleImmunizationChange = (index, value) => {
    let newData = { ...formData };
    newData["immunization"][index] = value;
    setFormData(newData);
  }

  const handleFormChange = (event, fieldName, index) => {
    if (
      fieldName === "jobBenefits" ||
      fieldName === "tagsKeyword" ||
      fieldName === "license" ||
      fieldName === "certification"
    ) {
      let newData = { ...formData };
      newData[fieldName][index] = event.target.value;
      setFormData(newData);
    } else if (
      fieldName === "jobCategory" ||
      fieldName === "payBasis" ||
      fieldName === "jobType" ||
      fieldName === "jobPostedDate" ||
      fieldName === "jobStartDate" ||
      fieldName === "shiftType" ||
      fieldName === "shiftHours" ||
      fieldName === "jobStatus" ||
      fieldName === "state" ||
      fieldName === "cities" ||
      fieldName === "timeZones" ||
      fieldName === "jobDesc"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: event,
      }));

      if (fieldName === "state") {
        setFormData((prevData) => ({
          ...prevData,
          ["cities"]: [],
        }));
      }
    } else {
      if (event.target.value < 0) return;
      setFormData((prevData) => ({
        ...prevData,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const isFormValid = () => {
    try {
      if (!formData?.title || formData?.title?.trim()?.length === 0) {
        showErrorAlert("Job title is required");
        return false;
      }

      if (formData?.tagsKeyword) {
        for (let i = 0; i < formData?.tagsKeyword?.length; i++) {
          if (formData.tagsKeyword[i].trim().length === 0) {
            showErrorAlert("Tags Specialities cannot be empty");
            return false;
          }
        }
      }

      if (!formData?.jobCategory) {
        showErrorAlert("Job Category is required");
        return false;
      }

      if (!formData?.weeklyEarnings && formData?.weeklyEarnings !== 0) {
        showErrorAlert("Weekly Earnings is required");
        return false;
      }

      if (!formData?.experience && formData?.experience !== 0) {
        showErrorAlert("Minimum experience is required");
        return false;
      }

      if (!formData?.jobType || formData?.jobType?.length < 1) {
        showErrorAlert("Job Type is required");
        return false;
      }

      if (!formData?.vacancies && formData?.vacancies !== 0) {
        showErrorAlert("No of Position is required");
        return false;
      }

      if (!formData?.shiftType) {
        showErrorAlert("Shift Type is required");
        return false;
      }

      if (!formData?.shiftHours) {
        showErrorAlert("Shift Hours is required");
        return false;
      }

      if (!formData?.jobPostedDate) {
        showErrorAlert("Job start date is required");
        return false;
      }

      if (!formData?.jobStatus) {
        showErrorAlert("Job status is required");
        return false;
      }

      if (!formData?.state) {
        showErrorAlert("State is required");
        return false;
      }

      if (!formData.jobDesc || formData.jobDesc.trim().length === 0) {
        showErrorAlert("Job description is required");
        return false;
      }

      if (formData?.jobBenefits) {
        for (let i = 0; i < formData?.jobBenefits?.length; i++) {
          if (formData.jobBenefits[i].trim().length === 0) {
            showErrorAlert("Job benefits cannot be empty");
            return false;
          }
        }
      }

      if (formData?.license) {
        for (let i = 0; i < formData?.license?.length; i++) {
          if (formData.license[i].trim().length === 0) {
            showErrorAlert("License cannot be empty");
            return false;
          }
        }
      }

      if (formData?.certification) {
        for (let i = 0; i < formData?.certification?.length; i++) {
          if (formData.certification[i]?.trim().length === 0) {
            showErrorAlert(`Certificate field cannot empty at index ${i + 1}`);
            return false;
          }
        }
      }

      if (formData?.immunization) {
        for (let i = 0; i < formData?.immunization?.length; i++) {
          if (formData.immunization[i]?.trim().length === 0) {
            showErrorAlert(`Immunization field cannot empty at index ${i + 1}`);
            return false;
          }
        }
      }

      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitForm = async () => {
    try {
      let data = { ...formData };
      if (!isFormValid()) return;
      setIsLoading(true);

      let coordinates = data["cities"].map((item) => ({
        type: "Point",
        coordinates: [
          parseFloat(item?.value?.longitude),
          parseFloat(item?.value?.latitude),
        ],
      }));
      coordinates.push({
        type: "Point",
        coordinates: [
          parseFloat(formData?.state?.value?.longitude),
          parseFloat(formData?.state?.value?.latitude),
        ],
      });
      data["jobCategory"] = data["jobCategory"]?.value;
      data["jobType"] = data["jobType"]?.map((item) => item?.value);
      data["shiftType"] = data["shiftType"]?.value;
      data["shiftHours"] = data["shiftHours"]?.value;
      data["jobStatus"] = data["jobStatus"]?.value;
      data["stateCode"] = data["state"]?.value?.state_code;
      data["state"] = data["state"]?.value?.name;
      data["cities"] = data["cities"]?.map((item) => item?.value?.name);
      data["timeZones"] = data["timeZones"]?.map((item) => item.value);
      data["locations"] = coordinates;

      const response = await Instance.put(`/editJob/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        showSuccessAlert(response?.data?.message);
        navigate("/jobs");
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(error?.response?.data?.message || "An error occured");
    } finally {
      setIsLoading(false);
    }
  };

  const disabledDateTime = (current) => {
    const currentMoment = moment();
    if (current && current.isSame(currentMoment, 'day')) {
      return {
        disabledHours: () => [...Array(24).keys()].splice(currentMoment.hour() + 1),
        disabledMinutes: () => [...Array(60).keys()].splice(currentMoment.minute() + 1),
        disabledSeconds: () => [...Array(60).keys()].splice(currentMoment.second() + 1),
      };
    }
    return {};
  };

  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <div>
        <Sidebar />
        <Header />
        <div className="main-wrapper">
          <div className="title-add-jobs">
            <h3>Edit Job</h3>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <Input
                placeholder="Job Title *"
                className="form-control mb-3 placeholder-gray"
                value={formData?.title || ""}
                name="title"
                onChange={(e) => handleFormChange(e, "title")}
                required
              />
            </div>

            <div className="my-4">
              <div className="title-add-salary">
                <h4>Tags/Specialities</h4>
              </div>
              <div className="d-flex gap-2 mt-3 flex-wrap">
                {formData?.tagsKeyword?.map((data, index) => (
                  <div key={index} className="d-flex align-items-center me-3">
                    <input
                      className="add-benefit-input me-2"
                      type="text"
                      value={data}
                      onChange={(event) =>
                        handleFormChange(event, "tagsKeyword", index)
                      }
                      placeholder="Add Tags/Keywords"
                    />
                    <FaTrash
                      style={{ color: "orangered" }}
                      onClick={() => {
                        let newData = { ...formData };
                        newData["tagsKeyword"] = newData["tagsKeyword"]?.filter(
                          (item, idx) => idx !== index
                        );
                        setFormData(newData);
                      }}
                    />
                  </div>
                ))}
                <button
                  className="add-benefit-button d-flex gap-2 align-items-center"
                  onClick={handleAddTagsKeyword}
                >
                  Add <PlusOutlined style={{ color: "#ce1b28" }} />
                </button>
              </div>
            </div>

            <div className="col-lg-4">
              <Select
                options={JobCategoryOptions}
                placeholder="Job Category *"
                value={formData?.jobCategory}
                onChange={(e) => handleFormChange(e, "jobCategory")}
                required
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="title-add-salary">
              <h4>Salary</h4>
            </div>
            <div className="col-lg-4">
              <Input
                type="number"
                placeholder="Weekly Earnings*"
                className="form-control mb-3 placeholder-gray"
                name="weeklyEarnings"
                value={formData?.weeklyEarnings}
                onChange={(e) => handleFormChange(e, "weeklyEarnings")}
              />
            </div>
            <div className="col-lg-4">
              <Input
                type="number"
                placeholder="Travel Per Diems /Week"
                className="form-control mb-3 placeholder-gray"
                name="travelPerDiems"
                value={formData?.travelPerDiems}
                onChange={(e) => handleFormChange(e, "travelPerDiems")}
              />
            </div>
            <div className="col-lg-4">
              <Input
                type="number"
                placeholder="Meals pay /day"
                className="form-control mb-3 placeholder-gray"
                name="mealsPay"
                value={formData?.mealsPay}
                onChange={(e) => handleFormChange(e, "mealsPay")}
              />
            </div>
            <div className="col-lg-4">
              <Input
                type="number"
                placeholder="Housing pay /day"
                className="form-control mb-3 placeholder-gray"
                name="housingPay"
                value={formData?.housingPay}
                onChange={(e) => handleFormChange(e, "housingPay")}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="title-add-salary">
              <h4>Advance Info</h4>
            </div>

            <div className="col-lg-4">
              <Input
                type="number"
                placeholder="Minimum Experience in Years*"
                className="form-control mb-3 placeholder-gray"
                name="experience"
                value={formData?.experience}
                onChange={(e) => handleFormChange(e, "experience")}
              />
            </div>

            <div className="col-lg-4">
              <Select
                isMulti
                options={JobTypeOptions}
                placeholder="Job Type*"
                name="jobType"
                value={formData?.jobType}
                onChange={(e) => handleFormChange(e, "jobType")}
              />
            </div>

            <div className="col-lg-4">
              <Input
                type="number"
                className="form-control mb-3 placeholder-gray"
                placeholder="No Of Positions*"
                value={formData?.vacancies}
                name="vacancies"
                onChange={(e) => handleFormChange(e, "vacancies")}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4">
              <div className="date-picker-container">
                <DatePicker
                  name="jobPostedDate"
                  disabledTime={disabledDateTime}
                  maxDate={dayjs(new Date(Date.now()))}
                  value={formData?.jobPostedDate || null}
                  onChange={(e) => handleFormChange(e, "jobPostedDate")}
                  className="expiration-date-picker col-lg-12"
                  placeholder="Job Posted Date *"
                  format={'MM/DD/YYYY hh:mm A'}
                  showTime
                  required
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="date-picker-container">
                <DatePicker
                  name="jobStartDate"
                  minDate={dayjs(new Date(Date.now()))}
                  value={formData?.jobStartDate || null}
                  onChange={(e) => handleFormChange(e, "jobStartDate")}
                  className="expiration-date-picker col-lg-12"
                  placeholder="Job Start Date"
                  format={'MM/DD/YYYY'}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <Select
                options={ShiftTypeOptions}
                placeholder="Shift Type*"
                value={formData?.shiftType}
                onChange={(e) => handleFormChange(e, "shiftType")}
              />
            </div>

            <div className="col-lg-4 mt-3">
              <Select
                options={ShiftHoursOptions}
                placeholder="Shift Hours*"
                value={formData?.shiftHours || ""}
                onChange={(e) => handleFormChange(e, "shiftHours")}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4">
              <Select
                options={JobStatusOption}
                placeholder="Job Status *"
                name="jobStatus"
                value={formData?.jobStatus || ""}
                onChange={(e) => handleFormChange(e, "jobStatus")}
                required
              />
            </div>

            <div className="col-lg-4">
              <Input
                type="number"
                className="form-control mb-3 placeholder-gray"
                placeholder="Duration"
                value={formData?.duration}
                name="duration"
                onChange={(e) => handleFormChange(e, "duration")}
              />
            </div>
          </div>

          <div className="form-location-div mt-4 ">
            <div className="title-add-location">
              <h4>Location</h4>
            </div>
            <div className="row p-2">
              <div className="col-lg-4">
                <Select
                  options={stateOptions}
                  placeholder="State *"
                  value={formData?.state}
                  onChange={(e) => {
                    handleFormChange(e, "state");
                  }}
                  required
                />
              </div>

              <div className="col-lg-4">
                <Select
                  options={cityOptions}
                  isDisabled={!formData?.state}
                  isMulti
                  placeholder="City"
                  value={formData?.cities}
                  onChange={(e) => handleFormChange(e, "cities")}
                />
              </div>

              <div className="col-lg-4">
                <Input
                  type="number"
                  placeholder="Zip Code"
                  className="form-control mb-3 placeholder-gray"
                  name="pinCode"
                  value={formData?.pinCode}
                  onChange={(e) => handleFormChange(e, "pinCode")}
                />
              </div>

              <div className="col-lg-4">
                <Select
                  options={TimeZoneOptions}
                  isMulti
                  placeholder="TimeZones"
                  value={formData?.timeZones}
                  onChange={(e) => handleFormChange(e, "timeZones")}
                />
              </div>
            </div>
          </div>

          <div className="my-4">
            <div className="title-add-salary">
              <h4>Job Benefit</h4>
            </div>
            <div className="d-flex gap-2 mt-3 flex-wrap">
              {formData?.jobBenefits?.map((data, index) => (
                <div key={index} className="d-flex align-items-center me-3">
                  <input
                    className="add-benefit-input me-2"
                    type="text"
                    name="jobBenefits"
                    value={data}
                    onChange={(e) => handleFormChange(e, "jobBenefits", index)}
                    placeholder="Add benefit"
                  />
                  <FaTrash
                    style={{ color: "orangered" }}
                    onClick={() => {
                      let newData = { ...formData };
                      newData["jobBenefits"] = newData["jobBenefits"]?.filter(
                        (item, idx) => idx !== index
                      );
                      setFormData(newData);
                    }}
                  />
                </div>
              ))}

              <button
                className="add-benefit-button d-flex gap-2 align-items-center"
                onClick={handleAddJobBenefit}
              >
                Add <PlusOutlined style={{ color: "#ce1b28" }} />
              </button>
            </div>
          </div>

          <div className="my-4">
            <div className="title-add-salary">
              <h4>License</h4>
            </div>
            <div className="d-flex gap-2 mt-3 flex-wrap">
              {formData?.license?.map((data, index) => (
                <div key={index} className="d-flex align-items-center me-3">
                  <Input
                    className="add-benefit-input me-2"
                    type="text"
                    value={data}
                    onChange={(e) => handleFormChange(e, "license", index)}
                    placeholder="Add License"
                  />
                  <FaTrash
                    style={{ color: "orangered" }}
                    onClick={() => {
                      let newData = { ...formData };
                      newData["license"] = newData["license"]?.filter(
                        (item, idx) => idx !== index
                      );
                      setFormData(newData);
                    }}
                  />
                </div>
              ))}
              <button
                className="add-benefit-button d-flex gap-2 align-items-center"
                onClick={handleAddLicense}
              >
                Add <PlusOutlined style={{ color: "#ce1b28" }} />
              </button>
            </div>
          </div>

          <div className="my-4">
            <div className="title-add-salary">
              <h4>Certification</h4>
            </div>
            <div className="d-flex gap-2 mt-3 flex-wrap">
              {formData?.certification?.map((item, index) => (
                <div key={index} className="d-flex align-items-center me-3">
                  <Input
                    className="add-benefit-input me-2"
                    type="text"
                    value={item}
                    onChange={(e) =>
                      handleCertificationChange(index, e.target.value)
                    }
                    placeholder="Add Certificate"
                  />
                  <FaTrash
                    style={{ color: "orangered", width: "32px" }}
                    onClick={() => {
                      let newData = { ...formData };
                      newData["certification"] = newData["certification"]?.filter(
                        (item, idx) => idx !== index
                      );
                      setFormData(newData);
                    }}
                  />
                </div>
              ))}
              <button
                className="add-benefit-button d-flex gap-2 align-items-center"
                onClick={handleAddCertification}
              >
                Add <PlusOutlined style={{ color: "#ce1b28" }} />
              </button>
            </div>
          </div>

          <div className="my-4">
            <div className="title-add-salary">
              <h4>Immunization</h4>
            </div>
            <div className="d-flex gap-2 mt-3 flex-wrap">
              {formData?.immunization?.map((item, index) => (
                <div key={index} className="d-flex align-items-center me-3">
                  <Input
                    className="add-benefit-input me-2"
                    type="text"
                    value={item}
                    onChange={(e) =>
                      handleImmunizationChange(index, e.target.value)
                    }
                    placeholder="Add Immunization"
                  />
                  <FaTrash
                    style={{ color: "orangered", width: "32px" }}
                    onClick={() => {
                      let newData = { ...formData };
                      newData["immunization"] = newData["immunization"]?.filter(
                        (item, idx) => idx !== index
                      );
                      setFormData(newData);
                    }}
                  />
                </div>
              ))}
              <button
                className="add-benefit-button d-flex gap-2 align-items-center"
                onClick={handleAddImmunization}
              >
                Add <PlusOutlined style={{ color: "#ce1b28" }} />
              </button>
            </div>
          </div>

          <div className="mt-4">
            <div className="title-add-salary">
              <h4>Job Description *</h4>
            </div>
            <div className="description-layout-jobs">
              <div className="description-title">
                <span>Description *</span>
              </div>
              <ReactQuill
                ref={quillRef}
                placeholder="Add your Job Description..."
                name="jobDesc"
                value={formData?.jobDesc || ""}
                onChange={(e) => handleFormChange(e, "jobDesc")}
                style={{ height: "180px", width: "100%", borderBottom: "none" }}
                modules={{
                  toolbar: {
                    container: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link", "image", "video"],
                      ["color", "background"],
                      ["clean"],
                    ],
                  },
                  clipboard: {
                    matchVisual: false,
                  },
                }}
              />
            </div>

            <div className="d-flex justify-content-between align-items-center mt-4">
              <button
                className="add-benefit-button d-flex gap-2 align-content-center"
                onClick={() => navigate("/jobs")}
              >
                Cancel
              </button>

              <button
                className="add-job-button d-flex gap-2 align-content-center"
                onClick={handleSubmitForm}
              >
                Edit Job
                <ArrowRightOutlined
                  style={{ color: "#fff", paddingTop: "2px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditJob;
