import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import ReactSelect from "react-select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import rightArrow from "../../Assets/icons/right-arrow.svg";
import WorldMap from "./WorldMap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const GraphMap = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const staticData = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Dataset 2",
        data: [500, 600, 700, 800, 900, 1000, 1100],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "#ce1b28",
        borderRadius: {
          topLeft: 12,
          topRight: 12,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#ce1b28",
      backgroundColor: "#FFF2F4",
      borderRadius: "8px",
    }),
  };

  const daysOptions = [
    { value: "today", label: "Today" },
    { value: "lastWeek", label: "Last Week" },
    { value: "last30Days", label: "Last 30 Days" },
  ];

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="chart-data-section">
          <div className="title-chart">
            <span>Application Overview</span>
            <ReactSelect
              value={selectedOption}
              onChange={handleOptionChange}
              options={daysOptions}
              styles={customStyles}
            />
          </div>

          <div className="chart-section">
            <Bar data={staticData} options={chartOptions} />
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="chart-data-section">
          <div className="title-chart">
            <span>Jobs by Region</span>

            <div className="arrow-bg">
              <img src={rightArrow} alt="" />
            </div>
          </div>

          <div id="map" className="map-container">
            <WorldMap />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphMap;
