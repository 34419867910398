import { createSlice } from "@reduxjs/toolkit";

const applicationSlice = createSlice({
  name: "applicationSlice",
  initialState: {
    applications: [],
  },
  reducers: {
    addAllApplications: (state, action) => {
      state.applications = action.payload;
    },
    editApplication: (state, action) => {
      const idx = state.applications.find(
        (data) => data._id?.toString() === action?.payload?._id?.toString()
      );
      if (idx === -1) {
        return;
      }
      state.applications[idx] = action.payload;
    },
    deleteApplication: (state, action) => {
      state.applications = state.applications.filter(
        (data) => data._id?.toString() !== action.payload?.toString()
      );
    },
  },
});

export const { addAllApplications, editApplication, deleteApplication } =
  applicationSlice.actions;

export default applicationSlice.reducer;
