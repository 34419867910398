import React, { useRef, useState } from 'react';
import { Input, Upload, Button, Spin, Modal, Form, Collapse } from 'antd';
import Sidebar from '../../Layout/Header/Sidebar';
import Header from '../../Layout/Header/Header';
import Instance from '../../AxiosConfig';
import { handleFileUpload, createUniqueS3FilenameWithDate, showErrorAlert } from '../../globalConstant';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { FaTrash } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { showSuccessAlert } from '../../globalConstant';

const { Panel } = Collapse;

const StyledUploadButton = styled(Button)`
  width: 100%;
  border: 1px solid #D5D8DE;
  border-radius: 6px;
`;

const AddPost = () => {
  const quillRef = useRef();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [bGImageURL, setBGImageURL] = useState(null);
  const [seoKeywords, setSeoKeywords] = useState([]);
  const [postDescription, setPostDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [faqFields, setFaqFields] = useState([]);
  const pattern = /^(?!.*--)[a-z0-9]+(-[a-z0-9]+)*$/;

  const handleFormDataChange = (event, fieldName) => {
    try {
      if (event.target.value < 0) return;
      let newData = { ...formData };
      newData[fieldName] = event.target.value;
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddSeoKeyword = () => {
    let newData = [...seoKeywords];
    newData.push("");
    setSeoKeywords(newData);
  };

  const seoKeywordsChange = (event, index) => {
    const updatedData = [...seoKeywords];
    updatedData[index] = event.target.value;
    setSeoKeywords(updatedData);
  };

  const handlePostImageSelect = (e) => {
    setBGImageURL(e.fileList[0]);
  };

  const handlePostContentChange = (content) => {
    setPostDescription(content);
  };

  const isFormValid = () => {
    if (!formData?.title || formData?.title?.trim() === '') {
      showErrorAlert('Post title required');
      return false;
    }
    if (!formData?.postUrl || formData?.postDescription?.trim() === '') {
      showErrorAlert('Post url required');
      return false;
    }

    if (!pattern.test(formData?.postUrl)) {
      showErrorAlert('Please enter valid post url');
      return false;
    }

    if (!formData?.seoTitle || formData?.seoTitle?.trim() === '') {
      showErrorAlert('SEO Title required');
      return false;
    }
    if (!formData?.seoDesc || formData?.seoDesc?.trim() === '') {
      showErrorAlert('SEO Description required');
      return false;
    }

    if (!seoKeywords || seoKeywords?.length === 0) {
      showErrorAlert('SEO Keywords required');
      return false;
    } else if (seoKeywords.length > 0) {
      const hasError = seoKeywords.some((item, index) => {
        if (!item || item?.trim() === '') {
          showErrorAlert(`SeoKeywords is required in ${index + 1} SeoKeywords input tag`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }
    if (!postDescription || postDescription?.trim() === '') {
      showErrorAlert('Post description required');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    try {
      if (!isFormValid()) return;
      setLoading(true);
      let fileName = (bGImageURL) ? createUniqueS3FilenameWithDate(bGImageURL?.name) : null;
      if (fileName) {
        let res = await handleFileUpload(bGImageURL?.originFileObj, fileName, bGImageURL?.type);
        if (!res) {
          showErrorAlert('Failed to upload bGImage');
          setLoading(false);
          return;
        }
      }

      let data = { ...formData, seoKeywords, postDescription };
      data.FAQs = faqFields
      data["bGImageURL"] = fileName;

      const response = await Instance.post('/addPost', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 201) {
        showSuccessAlert('Post Created Successfully!');
        setFormData({});
        setBGImageURL(null);
        setSeoKeywords([]);
        setPostDescription('');
        setFaqFields([]);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occurred');
      console.error(error)
    } finally {
      setLoading(false);
    }
  };

  const handleAddFaq = () => {
    setIsModalVisible(true);
  };

  const handleFaqChange = (index, event) => {
    const updatedFaqs = [...faqFields];
    updatedFaqs[index][event.target.name] = event.target.value;
    setFaqFields(updatedFaqs);
  };

  const handleAddFaqField = () => {
    setFaqFields([...faqFields, { question: '', answer: '' }]);
  };

  const handleRemoveFaqField = (index) => {
    const updatedFaqs = [...faqFields];
    updatedFaqs.splice(index, 1);
    setFaqFields(updatedFaqs);
  };

  const handleFaqOk = () => {
    try {
      for (let i = 0; i < faqFields.length; i++) {
        if (!faqFields[i].question) {
          showErrorAlert(`Question is required at index ${i + 1}`);
          return;
        }
        if (!faqFields[i].answer) {
          showErrorAlert(`Answer is required at index ${i + 1}`);
          return;
        }
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFaqCancel = () => {
    try {
      for (let i = 0; i < faqFields.length; i++) {
        if (!faqFields[i].question) {
          showErrorAlert(`Question is required at index ${i + 1}`);
          return;
        }
        if (!faqFields[i].answer) {
          showErrorAlert(`Answer is required at index ${i + 1}`);
          return;
        }
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {loading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <>
        <Sidebar />
        <Header />
        <div className="main-wrapper">
          <div className="add-post-timeline">
            <div className="title-timeline-add-post">
              <h3>Add Post</h3>
            </div>
          </div>

          <div className="steps-content">
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    placeholder="Title *"
                    className="form-control mb-3"
                    value={formData?.title}
                    name='title'
                    onChange={(e) => handleFormDataChange(e, 'title')}
                    suffix={<span>eg. Healthcare</span>}
                  />
                  <div className="mb-3" style={{ width: "100%" }}>
                    <Upload
                      onChange={handlePostImageSelect}
                      beforeUpload={() => false}
                      fileList={bGImageURL ? [bGImageURL] : []}
                    >
                      <StyledUploadButton icon={<UploadOutlined />}>
                        Upload Image
                      </StyledUploadButton>
                    </Upload>
                  </div>
                  <div className='post-url-input'>
                    <h5>www.bilkins.com/</h5>
                    <input
                      type="text"
                      placeholder='Post Url*'
                      value={formData?.postUrl || ""}
                      onChange={(e) => handleFormDataChange(e, 'postUrl')}
                    />
                  </div>

                  <Input
                    placeholder="SEO Title *"
                    className="form-control mb-3"
                    maxLength={60}
                    value={formData?.seoTitle}
                    name='seoTitle'
                    onChange={(e) => handleFormDataChange(e, 'seoTitle')}
                    suffix={<span>{formData?.seoTitle?.length} / 60</span>}
                  />
                  <Input
                    placeholder="SEO Description *"
                    className="form-control mb-3"
                    maxLength={160}
                    value={formData?.seoDesc}
                    name='seoDesc'
                    onChange={(e) => handleFormDataChange(e, 'seoDesc')}
                    suffix={<span>{formData?.seoDesc?.length} / 160</span>}
                  />

                  <div className="d-flex gap-2 mt-3 keywords-seo">
                    {seoKeywords.map((data, index) => (
                      <div key={index} className='d-flex align-items-center me-3 mb-2'>
                        <Input
                          className='add-benefit-input me-2'
                          type="text"
                          value={data}
                          onChange={(event) => seoKeywordsChange(event, index)}
                          placeholder="Add Seo Keyword"
                        />
                        <FaTrash style={{ color: "orangered" }} onClick={() => {
                          let newData = [...seoKeywords];
                          newData = newData.filter((item, idx) => idx !== index);
                          setSeoKeywords(newData);
                        }} />
                      </div>
                    ))}

                    <button className="add-benefit-button d-flex gap-2 align-items-center" onClick={handleAddSeoKeyword}>
                      Add Seo Keyword * <PlusOutlined style={{ color: "#ce1b28" }} />
                    </button>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className='description-layout'>
                    <div className='description-title'>
                      <span>Description *</span>
                    </div>
                    <ReactQuill
                      ref={quillRef}
                      value={postDescription}
                      onChange={handlePostContentChange}
                      placeholder="Type..."
                      style={{ height: '15rem', width: "100%", borderBottom: "none" }}
                      modules={{
                        toolbar: {
                          container: [
                            [{ 'header': [1, 2, 3, 4, false] }],
                            [{ 'font': [] }],
                            [{ size: [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                            { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link'],
                            ['color', 'background'],
                            ['clean']
                          ],
                        },
                        clipboard: {
                          matchVisual: false,
                        },
                      }}
                    />
                  </div>

                  <div className="add--faqs mt-3">
                    <button className="add-benefit-button d-flex gap-2 align-items-center" onClick={handleAddFaq}>
                      Add FAQ
                    </button>
                  </div>

                  <Collapse className="mt-3">
                    {faqFields.map((faq, index) => (
                      <Panel header={faq.question} key={index}>
                        <p>{faq.answer}</p>
                      </Panel>
                    ))}
                  </Collapse>
                </div>
              </div>

              <div className="next-button">
                <button style={{ width: "20%", marginTop: "15px" }} onClick={handleSave} >Save</button>
                <button className='cancel-button' onClick={() => { navigate('/post') }} >Cancel</button>
              </div>
            </div>
          </div>

          <Modal
            title="Add FAQ"
            open={isModalVisible}
            onOk={handleFaqOk}
            onCancel={handleFaqCancel}
          >
            {faqFields.map((field, index) => (
              <div key={index}>
                <Form.Item label="Question">
                  <Input
                    name="question"
                    value={field.question}
                    onChange={(e) => handleFaqChange(index, e)}
                  />
                </Form.Item>
                <Form.Item label="Answer">
                  <Input
                    name="answer"
                    value={field.answer}
                    onChange={(e) => handleFaqChange(index, e)}
                  />
                </Form.Item>
                <Button type="dashed" onClick={() => handleRemoveFaqField(index)}>
                  Remove
                </Button>
                <hr />
              </div>
            ))}
            <Button type="dashed" onClick={handleAddFaqField} style={{ width: '100%' }}>
              Add FAQ Field
            </Button>
          </Modal>
        </div>
      </>
    </>
  );
};

export default AddPost;
