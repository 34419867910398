import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import Header from "../../Layout/Header/Header";
import Sidebar from "../../Layout/Header/Sidebar";
import Dashboard from "../../Components/Dashboard/Dashboard";

const DashboardPage = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <div className="main-wrapper">
        <Dashboard />
      </div>
    </>
  );
};

export default DashboardPage;
