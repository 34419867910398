import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const data = [
  { location: "Virginia", color: "#57ff1f", coordinates: [-77.0369, 38.9072] },
  { location: "Florida", color: "#dc9700", coordinates: [-81.5158, 27.6648] },
  { location: "New York", color: "#00dcdc", coordinates: [-74.006, 40.7128] },
];

const Tooltip = ({ text }) => (
  <div
    style={{
      position: "absolute",
      top: "-20px",
      left: "20px",
      background: "#fff",
      padding: "5px",
      borderRadius: "5px",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    }}
  >
    {text}
  </div>
);

const WorldMap = () => {
  const [hoveredCountry, setHoveredCountry] = useState(null);

  return (
    <>
      <div className="world-map">
        <div className="world-map-chart">
          <ComposableMap
            projection="geoEqualEarth"
            projectionConfig={{ scale: 200, center: [0, 20] }}
            style={{ width: "100%", height: "auto" }}
          >
            <Geographies
              geography={require("../../Assets/jsons/ne_10m_admin_0_countries.json")}
            >
              {({ geographies }) =>
                geographies.map((geo, index) => {
                  const country = geo.properties.NAME;
                  const countryData = data.find((d) => d.country === country);

                  return (
                    <Geography
                      key={index}
                      geography={geo}
                      fill={countryData ? countryData.color : "#ce1b28"}
                      stroke="#FFF"
                      strokeWidth={5}
                      onMouseEnter={() => setHoveredCountry(country)}
                      onMouseLeave={() => setHoveredCountry(null)}
                      style={{
                        transition: "fill 0.3s",
                        fill: countryData ? countryData.color : "#000",
                        filter:
                          hoveredCountry === country
                            ? "brightness(150%)"
                            : "none",
                      }}
                    />
                  );
                })
              }
            </Geographies>

            {data.map((marker, index) => (
              <Marker
                key={index}
                coordinates={marker.coordinates}
                onMouseEnter={() => setHoveredCountry(marker.country)}
                onMouseLeave={() => setHoveredCountry(null)}
              >
                <circle
                  r={hoveredCountry === marker.country ? 10 : 8}
                  fill={marker.color}
                  stroke="#FFF"
                  strokeWidth={1}
                  style={{
                    transition: "r 0.3s",
                  }}
                />
                {hoveredCountry === marker.country && (
                  <Tooltip text={marker.country} />
                )}
              </Marker>
            ))}
          </ComposableMap>
          <div className="country-locations">
            {data.map((location, index) => (
              <div className="country-names" key={index}>
                <div className="country-pin-location">
                  <div className={`circle-country-${index}`}></div>
                </div>
                <div className="country-pin-names">
                  <span>{location.location} 50%</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorldMap;
