import Sidebar from "../../Layout/Header/Sidebar";
import Header from "../../Layout/Header/Header";
import PostList from "../../Components/Post/PostList";

const PostPortalPage = () => {

  return (
    <>
      <Sidebar />
      <Header />

      <div className="main-wrapper">
        <PostList />
      </div>
    </>
  );
};

export default PostPortalPage;
