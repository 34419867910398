import React, { useState, useRef } from 'react';
import profile from "../../Assets/user/edit-profile.png";
import { DatePicker } from 'antd';

const EditProfileForm = () => {
    const [formData, setFormData] = useState({
        name: 'Charlene Reed',
        email: 'charlenereed@gmail.com',
        permanentAddress: 'San Jose, California, USA',
        postalCode: '45962',
        userName: 'Charlene Reed',
        password: '**********',
        presentAddress: 'San Jose, California, USA',
        city: 'San Jose',
        country: 'USA',
    });

    const fileInputRef = useRef(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData((prevData) => ({
                    ...prevData,
                    profilePicture: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };

    return (
        <div className="edit-profile-section">
            <h2>Edit Profile</h2>
            <hr />
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="profile-icon-section">
                            <img src={profile} alt="Profile" className="profile-image" />
                            <button type="button" className="edit-icon-button" onClick={handleEditClick}>
                                <i className="edit-icon">✎</i>
                            </button>

                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                    <div className="col-lg-9">

                        <div className="row">
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="name">Your Name</label>
                                    <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="dateOfBirth">Date of Birth</label>
                                    <DatePicker
                                        onChange={(e) => setFormData((prevData)=>({
                                            ...prevData,
                                            ['dateOfBirth']: e,
                                        }))}
                                        value={formData?.dateOfBirth || null}
                                        className="form-control"
                                        style={{ width: '100%' }}
                                        placeholder="Enter DOB"
                                        format={'MM/DD/YYYY'}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="permanentAddress">Permanent Address</label>
                                    <input type="text" id="permanentAddress" name="permanentAddress" value={formData.permanentAddress} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="postalCode">Postal Code</label>
                                    <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="userName">User Name</label>
                                    <input type="text" id="userName" name="userName" value={formData.userName} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id="password" name="password" value={formData.password} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="presentAddress">Present Address</label>
                                    <input type="text" id="presentAddress" name="presentAddress" value={formData.presentAddress} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input type="text" id="city" name="city" value={formData.city} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2"><div className="form-group">
                                <label htmlFor="country">Country</label>
                                <input type="text" id="country" name="country" value={formData.country} onChange={handleInputChange} />
                            </div></div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="save-button">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditProfileForm;
