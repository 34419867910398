import React, { useState, useRef } from "react";
import "./user.css";
import { Modal, Button, Input, Checkbox } from "antd";
import { FiEye, FiEyeOff } from "react-icons/fi";
import UserList from "./UserList";
import Instance from "../../AxiosConfig";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import { addNewUser } from "../../features/usersSlice";
import { showErrorAlert, showSuccessAlert } from "../../globalConstant";

const roleOptions = [
  { value: "NORMAL", label: "NORMAL" },
  { value: "ADMIN", label: "ADMIN" },
];

const User = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const childRef = useRef();

  const emailregex = /[a-zA-Z0-9]{1}@[a-z]{4,}.[a-z]{2,}$/;
  const isFormValid = () => {
    try {
      if (!formData?.firstName || formData?.firstName?.trim() === "") {
        showErrorAlert('Please enter Firstname');
        return false;
      }
      if (!formData?.lastName || formData?.lastName?.trim() === "") {
        showErrorAlert('Please enter Lastname');
        return false;
      }
      if (!formData.email || formData?.email?.trim() === '') {
        showErrorAlert('Please enter Email');
        return false;
      } else if (!formData?.email?.match(emailregex)) {
        showErrorAlert('Please enter email correctly');
        return false;
      }
      if (!formData?.password || formData?.password?.trim() === "") {
        showErrorAlert('Please enter Password');
        return false;
      }
      if (!formData?.termsConditionAgreed) {
        showErrorAlert('Please accept the terms & conditions');
        return false;
      }
      if (!formData?.role || formData?.role === null) {
        showErrorAlert('Please enter Role');
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  const handleFieldChange = (e, fieldName) => {
    try {
      if (fieldName === 'termsConditionAgreed') {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: e.target.checked
        }))
      } else if (fieldName === 'role') {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: e
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: e.target.value
        }))
      }
    } catch (error) {
      console.error(error);
    }
  }

  const generatePassword = (length = 12) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let newPassword = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }
    setFormData({ ...formData, password: newPassword });
  };

  const handleSaveUser = async (event) => {
    try {
      event.preventDefault();
      if (!isFormValid()) return;
      let payload = { ...formData };
      payload['role'] = payload?.role?.value;

      const response = await Instance.post('/register', payload, {});

      if (response.status === 201) {
        showSuccessAlert(response?.data?.message || 'User saved successfully');
        callChildFunction(response?.data?.newUser);
        dispatch(addNewUser(response?.data?.newUser));
        setIsModalVisible(false)
        setFormData({});
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
      console.error(error);
    }
  };

  const callChildFunction = (newUser) => {
    if (childRef.current) {
      childRef.current.handleAddUser(newUser);
    }
  };

  return (
    <>
      <div className="main-title-all">
        <span>Users</span>
        <button type="primary" onClick={() => setIsModalVisible(true)}>
          Add User
        </button>
      </div>

      <UserList ref={childRef} />

      <Modal
        title="Add User"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <hr />,
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSaveUser}>
            Save
          </Button>,
        ]}
      >
        <div className="add-user-section">
          <hr />
          <div className="row">
            <div className="col-lg-6 mb-3">
              <Input
                placeholder="First name *"
                suffix={<span>Enter Firstname</span>}
                className='form-control'
                value={formData?.firstName}
                onChange={(e) => handleFieldChange(e, 'firstName')}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                placeholder="Last name *"
                suffix={<span>Enter Lastname</span>}
                className='form-control'
                value={formData?.lastName}
                onChange={(e) => handleFieldChange(e, 'lastName')}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                placeholder="Discipline"
                suffix={<span>Enter Discipline</span>}
                className='form-control'
                value={formData?.discipline}
                onChange={(e) => handleFieldChange(e, 'discipline')}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <Input
                placeholder="Email *"
                suffix={<span>Enter Email Id</span>}
                className='form-control'
                value={formData?.email}
                onChange={(e) => handleFieldChange(e, 'email')}
              />
            </div>
            <div className="col-12 mb-3">
              <Input
                placeholder="ReferralCode"
                autoComplete='off'
                suffix={<span>Enter ReferralCode</span>}
                className='form-control'
                value={formData?.referralCode}
                onChange={(e) => handleFieldChange(e, 'referralCode')}
              />
            </div>
            <div className="col-12 mb-3">
              <div className="password-add-user">
                <Input
                  type={showPassword ? 'text' : 'password'}
                  value={formData?.password}
                  onChange={(e) => handleFieldChange(e, 'password')}
                  placeholder="Password *"
                  autoComplete='off'
                  suffix={
                    <div className='d-flex align-items-center justify-content-between'>
                      <span className='generate-password' onClick={() => { generatePassword() }}>Generate Password</span>
                    </div>
                  }
                  className='form-control'
                />

                <div className="password-icon">
                  {showPassword ? (
                    <FiEye onClick={() => setShowPassword(false)} style={{ cursor: 'pointer', color: "#ce1b28" }} />
                  ) : (
                    <FiEyeOff onClick={() => setShowPassword(true)} style={{ cursor: 'pointer', color: "#ce1b28" }} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              {/* {!true && (
                <Checkbox checked={true} onChange={(e) => console.log()}>
                  Send User Notification
                </Checkbox>
              )}
              {true && ( */}
              <Checkbox
                checked={formData?.termsConditionAgreed}
                onChange={(e) => { handleFieldChange(e, 'termsConditionAgreed') }}
              >
                By creating an account with Bilkins you agree to the Terms and Conditions
              </Checkbox>
              {/* )} */}
            </div>
            <div className="col-12 mb-3">
              <label>Role *</label>
              <Select
                options={roleOptions}
                value={formData?.role}
                onChange={(e) => handleFieldChange(e, 'role')}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default User;
