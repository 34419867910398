import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { Table, Input, Modal, DatePicker } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Select from "react-select";
import Instance from "../../AxiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { addAllUsers, editUser, deleteUser } from "../../features/usersSlice";
import Swal from 'sweetalert2';
import { showSuccessAlert, showErrorAlert } from "../../globalConstant";

const roleOptions = [
  { value: "NORMAL", label: "NORMAL" },
  { value: "ADMIN", label: "ADMIN" },
];

const UserList = forwardRef((props, ref) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.users?.users);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [fileterParameters, setFilterParameters] = useState({});
  const [allData, setAllData] = useState([]);

  const handleAddUser = (newUser) => {
    try {
      let newData = [...allData, newUser];
      setAllData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  useImperativeHandle(ref, () => ({
    handleAddUser
  }));

  const fetchUserData = async () => {
    try {
      const response = await Instance.get("/getAllUsers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.status === 200) {
        let data = response?.data?.users;
        data = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAllData(data);
        dispatch(addAllUsers(data));
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const url = `/deleteUser/${id}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.status === 200) {
        dispatch(deleteUser(id));
        let curData = allData?.filter((item) => item?._id?.toString() !== id?.toString());
        setAllData(curData);
        showSuccessAlert('User deleted successfuly');
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  };

  const handleEdit = (record) => {
    setSelectedRowData(record);
    setEditModalVisible(true);
  };

  const handleUpdate = async () => {
    try {
      let payload = { ...selectedRowData };
      payload["role"] = payload?.role?.value;
      const response = await Instance.put(`/editUser/${payload?._id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      if (response.status === 200) {
        dispatch(editUser(payload));
        setEditModalVisible(false);
        showSuccessAlert("User updated Successfully");
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  };

  const handleCheckboxChange = (category) => {
    const newSelectedCategories = [...selectedCategories];
    if (newSelectedCategories.some((cat) => cat.value === category.value)) {
      newSelectedCategories.splice(
        newSelectedCategories.findIndex((cat) => cat.value === category.value),
        1
      );
    } else {
      newSelectedCategories.push(category);
    }
    setSelectedCategories(newSelectedCategories);
  };

  const columns = [
    {
      title: "Firstname",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (firstName) => {
        if (firstName.trim() === "") {
          return "NA";
        }
        else {
          return firstName;
        }
      }
    },
    {
      title: "Lastname",
      dataIndex: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: (lastName) => {
        if (lastName.trim() === "") {
          return "NA";
        }
        else {
          return lastName;
        }
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <EditOutlined
          className="edit-button-table"
          onClick={() => handleEdit(record)}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <DeleteOutlined
          className="delete-button-table"
          onClick={() => {
            Swal.fire({
              title: "Are you sure",
              text: "You want to Delete?",
              showCancelButton: true,
              confirmButtonColor: "#555",
              cancelButtonColor: "#ce1b28",
              confirmButtonText: "Yes, Delete!"
            }).then((result) => {
              if (result.isConfirmed) {
                handleDelete(record._id)
              }
            })
          } }
        />
      ),
    },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleFilter = () => {
    let filteredData = [...allData];
    if (fileterParameters?.date) {
      filteredData = filteredData?.filter((item) => {
        const itemDate = formatDate(item.createdAt);
        const filterDate = formatDate(fileterParameters?.date);
        return itemDate === filterDate;
      });
    }

    if (fileterParameters?.roles && fileterParameters?.roles.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.roles?.findIndex((d) => d.value === item?.role);
        if (exist !== -1) return 1;
        return 0;
      })
    }

    if (fileterParameters?.selectedRows && fileterParameters?.selectedRows.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.selectedRows.findIndex((d) => d?.toString() === item?._id?.toString());
        if (exist !== -1) return 1;
        return 0;
      });
    }

    dispatch(addAllUsers(filteredData));
  };


  useEffect(() => {
    if (fileterParameters?.searchText === '') {
      dispatch(addAllUsers(allData));
    };
    let filteredData = allData?.filter(item => JSON.stringify(item)?.toLowerCase().includes(fileterParameters?.searchText?.toLowerCase()));
    dispatch(addAllUsers(filteredData));
  }, [fileterParameters?.searchText]);

  return (
    <>
      <div className="post-list-section">
        <div className="filter-row">
          <div className="select-options">
            {/* <Select
              options={bulkOptions}
              onChange={setBulkOption}
              placeholder="Bulk Option"
              styles={{ ...customStyles, menuPortal: document.body }}
            /> */}
            <DatePicker
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['date'] = e;
                setFilterParameters(newData);
              }}
              className="custom-date-picker me-2"
            />
            <Select
              isMulti
              options={roleOptions}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['roles'] = e;
                setFilterParameters(newData);
              }}
              placeholder="Change role to..."
              styles={{ ...customStyles, menuPortal: document.body }}
            />
          </div>
          <div className="search-table-container">
            <Input
              placeholder="Search..."
              value={fileterParameters?.searchText}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['searchText'] = e.target.value;
                setFilterParameters(newData);
              }}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <button onClick={handleFilter}>
              <FilterOutlined /> <span>Filter</span>
            </button>
          </div>
        </div>

        <div className={`filter-panel ${showFilterPanel ? "show" : ""}`}>
          <div className="filter-title">
            <span>Filter</span>
            <CloseOutlined className="close-icon" onClick={handleFilter} />
          </div>
          <hr />

          {roleOptions.map((option) => (
            <div key={option.value} className="checkbox-item">
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                checked={selectedCategories.some(
                  (category) => category.value === option.value
                )}
                onChange={() => handleCheckboxChange(option)}
              />
              <label className="mx-2" htmlFor={option.value}>
                {option.label}
              </label>
            </div>
          ))}

          <button className="filter-button" onClick={handleFilter}>
            Filter
          </button>
        </div>

        <div className="table-list">
          <Table
            columns={columns}
            dataSource={userData}
            rowKey={(data) => data?._id}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setFilterParameters(prevData => ({
                  ...prevData,
                  ['selectedRows']: selectedRowKeys
                }));
              },
            }}
          />
        </div>

        <Modal
          title="Edit User"
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={null}
        >
          <hr />
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label>Firstname *</label>
              <Input
                value={selectedRowData ? selectedRowData?.firstName : ""}
                onChange={(e) =>
                  setSelectedRowData({
                    ...selectedRowData,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Lastname *</label>
              <Input
                value={selectedRowData ? selectedRowData?.lastName : ""}
                onChange={(e) =>
                  setSelectedRowData({
                    ...selectedRowData,
                    lastName: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-lg-6 mb-3">
              <label>Email *</label>
              <Input
                disabled
                value={selectedRowData ? selectedRowData?.email : ""}
              />
            </div>

            <div className="col-lg-6 mb-3">
              <label>Role </label>
              <Select
                options={roleOptions}
                onChange={(e) => {
                  let prevData = { ...selectedRowData };
                  prevData['role'] = e;
                  setSelectedRowData(prevData);
                }}
                value={selectedRowData?.role?.value ? selectedRowData?.role : { value: selectedRowData?.role, label: selectedRowData?.role }}
                placeholder="Change role to..."
                styles={{ ...customStyles, menuPortal: document.body }}
              />
            </div>

            <div className="col-lg-6 mb-3">
              <label>Discipline </label>
              <Input
                value={selectedRowData ? selectedRowData?.discipline : ""}
                onChange={(e) =>
                  setSelectedRowData({
                    ...selectedRowData,
                    discipline: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-lg-6 mb-3">
              <label>Referralcode </label>
              <Input
                value={selectedRowData ? selectedRowData?.referralCode : ""}
                onChange={(e) =>
                  setSelectedRowData({
                    ...selectedRowData,
                    referralCode: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-12 mb-3">
              <button className="edit-button" onClick={handleUpdate}>
                Update
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

export default UserList;
