import { v4 as uuidv4 } from "uuid";
import Instance from "./AxiosConfig";
import Swal from "sweetalert2";

export const FRONTEND_BASE_URL = `https://www.bilkins.com/`;

export const handleFileUpload = async (file, fileName, type) => {
    try {
      const response = await Instance.get('/getUploadUrl', {
        params: {
          fileName: fileName,
          contentType: type,
        },
      });
      const presignedUrl = response?.data?.url;

      await Instance.put(presignedUrl, file, {
        headers: {
          'Content-Type': type,
        },
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  export const createUniqueS3FilenameWithDate = (filename) => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    const uniqueId = uuidv4();
    const ext = filename.split('.').pop();
    const baseName = filename.replace(`.${ext}`, '');
    const validBaseName = baseName.replace(/[^a-zA-Z0-9-_]/g, '_');
    const uniqueFilename = `${validBaseName}-${date}-${uniqueId}.${ext}`;
    return uniqueFilename;
  }

  export const showErrorAlert = (message) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Error",
      text: message,
      customClass: {
        icon: "centered-icon",
      },
    });
  };
  export const showSuccessAlert = (message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Successful",
      text: message,
      customClass: {
        icon: "centered-icon",
      },
    });
  };

  export const dateFormat = (curDate) => {
    const date = new Date(curDate);
    return `${(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}`
  };
