import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import Sidebar from '../../Layout/Header/Sidebar';
import Header from '../../Layout/Header/Header';
import ApplicantList from '../../Components/Applicants/ApplicantList';


const ApplicantsPortalPage = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <div className="main-wrapper">
                <ApplicantList />
            </div>
        </>
    )
}

export default ApplicantsPortalPage