import { createSlice } from "@reduxjs/toolkit";

const mediaSlice = createSlice({
    name: 'mediaSlice',
    initialState: {
        media: []
    },
    reducers: {
        addAllMedia: (state, action) => {
            state.media = action.payload;
        },
        deleteMedia: (state, action) => {
            state.media = state.media.filter((item) => item?._id?.toString() !== action.payload?.toString())
        }
    }
});

export const { addAllMedia, deleteMedia } = mediaSlice.actions;

export default mediaSlice.reducer;