import React, { useEffect, useState } from "react";
import Sidebar from "../../Layout/Header/Sidebar";
import Header from "../../Layout/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrash } from "react-icons/fa6";
import Select from 'react-select';
import Instance from '../../AxiosConfig';
import { Spin, DatePicker } from "antd";
import moment from 'moment/moment'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import stateCityOptions from '../../jsons/statesCity';
import "./applicants.css"
import { showErrorAlert, showSuccessAlert } from "../../globalConstant";

const certificationOptions = [
  { value: 'ARRT', label: 'ARRT' },
  { value: 'RCIS', label: 'RCIS' },
  { value: 'FLOURO', label: 'FLOURO' }
];

const categoryOptions = [
  { label: "Cardiopulmonary", value: "Cardiopulmonary" },
  { label: "Cath Lab", value: "Cath Lab" },
  { label: "Home Health", value: "Home Health" },
  { label: "Laboratory", value: "Laboratory" },
  { label: "Long Term Care", value: "Long Term Care" },
  { label: "Nursing", value: "Nursing" },
  { label: "Theraphy", value: "Theraphy" },
  { label: "Radiology", value: "Radiology" }
];

const schoolTypeOptions = [
  { label: "College", value: "College" },
  { label: "Trade School", value: "Trade School" },
  { label: "Other", value: "Other" }
];

const ViewApplication = () => {
  const { id } = useParams();
  const specialityOptions = [];
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const stateLicenceOptions = stateCityOptions?.states?.map((item) => ({
    label: item.name,
    value: item.name
  }));

  const fetchApplicantData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get(`/getApplication/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200 && response?.data?.application) {
        setFormData(response?.data?.application);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicantData();
  }, []);


  const handleAddEducation = (e) => {
    e.preventDefault();
    try {
      let newData = { ...formData };
      newData['educations'] = [...newData['educations'], {}];
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddCertification = (e) => {
    e.preventDefault();
    try {
      let newData = { ...formData };
      newData['certifications'] = [...newData['certifications'], {}];
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddLicence = (e) => {
    e.preventDefault();
    try {
      let newData = { ...formData };
      newData['licences'] = [...newData['licences'], {}];
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddExperience = (e) => {
    e.preventDefault();
    try {
      let newData = { ...formData };
      newData['experiences'] = [...newData['experiences'], {}];
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddPrevEmployment = (e) => {
    e.preventDefault();
    try {
      let newData = { ...formData };
      newData['prevEmployements'] = [...newData['prevEmployements'], {}];
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleEducationFieldChange = (e, fieldName, index) => {
    try {
      let newData = { ...formData };
      if (fieldName === 'schoolType' || fieldName === 'schoolState' || fieldName === 'graduateDate') {
        newData['educations'][index][fieldName] = e;
      } else {
        newData['educations'][index][fieldName] = e.target.value;
      }
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCertificationFieldChange = (e, fieldName, index) => {
    try {
      let newData = { ...formData };
      if (fieldName === 'expirationDate') {
        newData['certifications'][index][fieldName] = e;
      } else newData['certifications'][index][fieldName] = e.target.value;
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLicenseFieldChange = (e, fieldName, index) => {
    try {
      let newData = { ...formData };
      if (fieldName === 'state' || fieldName === 'issuedDate' || fieldName === 'expirationDate') {
        newData['licences'][index][fieldName] = e;
        setFormData(newData);
      } else {
        newData['licences'][index][fieldName] = e.target.value;
        setFormData(newData);
      }

    } catch (error) {
      console.error(error);
    }
  };

  const handleExperienceFieldChange = (e, fieldName, index) => {
    try {
      let newData = { ...formData };
      newData['experiences'][index][fieldName] = e.target.value;
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrevEmploymentFieldChange = (e, fieldName, index) => {
    try {
      let newData = { ...formData };
      if (fieldName === 'state' || fieldName === 'startDate' || fieldName === 'endDate') {
        newData['prevEmployements'][index][fieldName] = e;
      } else {
        newData['prevEmployements'][index][fieldName] = e.target.value;
      }
      setFormData(newData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleFieldChange = (e, fieldName) => {
    let newData = { ...formData };

    if (fieldName === 'actionLimitaions' || fieldName === 'drugScreen' || fieldName === "criminalBackgroundCheck" || fieldName === "limitationDescription") {
      newData[fieldName] = e.target.value;
    } else if (fieldName === 'speciality' || fieldName === 'category' || fieldName === 'certifications' || fieldName === 'stateLicenses' || fieldName === "state" || fieldName === 'mobileNumber' || fieldName === 'dateOfBirth') {
      newData["basicInfo"][fieldName] = e;
    } else if (fieldName === 'termsConditionAgreed') {
      newData[fieldName] = e.target.checked;
    } else {
      newData["basicInfo"][fieldName] = e.target.value;
    }
    setFormData(newData);
  }

  const validatePhoneNumber = (value) => {
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber && phoneNumber.isValid()) {
      return true;
    } else {
      return false;
    }
  };

  const isFormValid = () => {
    if (!formData?.basicInfo?.firstName || formData?.basicInfo?.firstName?.trim() === '') {
      showErrorAlert('First Name is required');
      return false;
    }
    if (!formData?.basicInfo?.lastName || formData?.basicInfo?.lastName?.trim() === '') {
      showErrorAlert('Last Name is required');
      return false;
    }
    if (!formData?.basicInfo?.category || formData?.basicInfo?.category?.value?.trim() === '') {
      showErrorAlert('Category is required');
      return false;
    }
    if (!formData?.basicInfo?.speciality || formData?.basicInfo?.speciality?.value?.trim() === '') {
      showErrorAlert('Speciality is required');
      return false;
    }

    if (!formData?.basicInfo?.mobileNumber || formData?.basicInfo?.mobileNumber?.length < 6) {
      showErrorAlert('Mobile Number reauired');
      return false;
    } else if (!validatePhoneNumber(formData?.basicInfo?.mobileNumber)) {
      showErrorAlert("Please enter valid Mobile Number");
      return false;
    }

    if (formData?.educations.length > 0) {
      const hasError = formData?.educations.some((item, index) => {
        if (!item?.schoolName || item?.schoolName?.trim() === '') {
          showErrorAlert(`School Name is required in ${index + 1} education detail`);
          return true;
        }
        if (!item?.schoolType || item?.schoolType?.value?.trim() === '') {
          showErrorAlert(`School Type is required in ${index + 1} education detail`);
          return true;
        }
        if (!item?.schoolCity || item?.schoolCity?.trim() === '') {
          showErrorAlert(`School City is required in ${index + 1} education detail`);
          return true;
        }
        if (!item?.schoolState || item?.schoolState?.value?.trim() === '') {
          showErrorAlert(`School state is required in ${index + 1} education detail`);
          return true;
        }
        if (!item?.degreeDiploma || item?.degreeDiploma?.trim() === '') {
          showErrorAlert(`Degree/Diploma is required in ${index + 1} education detail`);
          return true;
        }
        if (!item?.graduateDate || item?.graduateDate?.trim() === '') {
          showErrorAlert(`Graduate Date is required in ${index + 1} education detail`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }

    if (formData?.certifications.length > 0) {
      const hasError = formData?.certifications.some((item, index) => {
        if (!item?.certificateURL || item?.certificateURL?.trim() === '') {
          showErrorAlert(`Certificate URL is required in ${index + 1} certificate detail`);
          return true;
        }
        if (!item?.expirationDate || item?.expirationDate?.trim() === '') {
          showErrorAlert(`Expiration Date is required in ${index + 1} certificate detail`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }

    if (formData?.licences.length > 0) {
      const hasError = formData?.licences.some((item, index) => {
        if (!item?.state || item?.state?.value?.trim() === '') {
          showErrorAlert(`state is required in ${index + 1} Licenses detail`);
          return true;
        }
        if (!item?.issuedDate || item?.issuedDate?.trim() === '') {
          showErrorAlert(`Issued Date is required in ${index + 1} Licenses detail`);
          return true;
        }
        if (!item?.expirationDate || item?.expirationDate?.trim() === '') {
          showErrorAlert(`Expiration Date is required in ${index + 1} Licenses detail`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }

    if (formData?.experiences.length > 0) {
      const hasError = formData?.experiences.some((item, index) => {
        if (!item?.speciality || item?.speciality?.trim() === '') {
          showErrorAlert(`Speciality is required in ${index + 1} Experiences detail`);
          return true;
        }
        if (!item?.yearOFExperience || item?.yearOFExperience?.trim() === '') {
          showErrorAlert(`Year OF Experience is required in ${index + 1} Experiences detail`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }

    if (formData?.actionLimitaions === "true") {
      if (!formData?.limitationDescription || formData?.limitationDescription?.length < 10) {
        showErrorAlert('Please write the Description atleast 10 length of char');
        return false;
      }
    }

    if (formData?.prevEmployements.length > 0) {
      const hasError = formData?.prevEmployements.some((item, index) => {
        if (!item?.facility || item?.facility?.trim() === '') {
          showErrorAlert(`Facility is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.department || item?.department?.trim() === '') {
          showErrorAlert(`Department is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.supervisiorName || item?.supervisiorName?.trim() === '') {
          showErrorAlert(`Supervisior Name is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.startDate || item?.startDate?.trim() === '') {
          showErrorAlert(`Start Date is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.endDate || item?.endDate?.trim() === '') {
          showErrorAlert(`End Date is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.city || item?.city?.trim() === '') {
          showErrorAlert(`City is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.state || item?.state?.value?.trim() === '') {
          showErrorAlert(`State is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        if (!item?.hours || item?.hours?.trim() === '') {
          showErrorAlert(`Hours is required in ${index + 1} Previous Employements detail`);
          return true;
        }
        return false;
      });

      if (hasError) {
        return false;
      }
    }

    if (!formData?.termsConditionAgreed) {
      showErrorAlert('Please accept terms & condition before sumbit')
      return false;
    }

    return true;
  }

  const handleFormSubmission = async () => {
    try {
      if (!isFormValid()) return;
      setIsLoading(true);
      let payload = { ...formData };
      payload['basicInfo']['category'] = payload['basicInfo']['category']?.value || payload['basicInfo']['category'] || null;
      payload['basicInfo']['certifications'] = payload['basicInfo']['certifications']?.map((data) => (data?.value) ? data.value : data);
      payload['basicInfo']['state'] = payload['basicInfo']['state']?.value || payload['basicInfo']['state'];
      payload['basicInfo']['stateLicenses'] = payload['basicInfo']['stateLicenses']?.map((data) => (data?.value) ? data.value : data);
      payload['educations'] = payload['educations']?.map((item) => ({
        ...item,
        schoolType: item['schoolType']?.value || item['schoolType'],
        schoolState: item['schoolState']?.value || item['schoolState']
      }));
      payload['licences'] = payload['licences']?.map((item) => ({
        ...item,
        state: item['state']?.value || item['state']
      }));
      payload['prevEmployements'] = payload['prevEmployements']?.map((item) => ({
        ...item,
        state: item['state']?.value || item['state']
      }));

      const response = await Instance.put(`/editApplication/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        showSuccessAlert('Application Updated Successfully!');
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <Header />
      <Sidebar />
      <div className="main-wrapper">
        <div className="view-timeline">
          <div className="title-timeline-view">
            <h3>{`JobId`} <span style={{ color: "#ce1b28" }}>{`#${formData?.manualJobId || 'N/A'}`}</span></h3>
          </div>
        </div>

        <div className="row g-3 my-2 form-div-start">
          <h3>Basic Information</h3>
          <div className="col-md-6">
            <label htmlFor="inputfirstName" className="form-label">Legal First name*</label>
            <input type="text" className="form-control" required id="inputfirstName"
              placeholder='Enter First Name'
              value={formData?.basicInfo?.firstName || ""}
              name='firstName'
              onChange={(e) => handleFieldChange(e, 'firstName')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputmiddleName" className="form-label">Legal Middle name</label>
            <input type="text" className="form-control" id="inputmiddleName"
              placeholder='Enter Middle Name'
              value={formData?.basicInfo?.middleName || ""}
              name='middleName'
              onChange={(e) => handleFieldChange(e, 'middleName')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputlastName" className="form-label">Legal Last name*</label>
            <input type="text" className="form-control" required id="inputlastName"
              placeholder='Enter Last Name'
              value={formData?.basicInfo?.lastName || ""}
              name='lastName'
              onChange={(e) => handleFieldChange(e, 'lastName')}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="inputBirthday" className="form-label">Date of Birth</label>
            <DatePicker
              onChange={(e) => handleFieldChange(e, 'dateOfBirth')}
              value={formData?.basicInfo?.dateOfBirth || null}
              className="form-control"
              style={{ width: '100%' }}
              placeholder="Enter DOB"
              format={'MM/DD/YYYY'}
            />
          </div>



          <div className="col-md-6">
            <label htmlFor="inputCategory" className="form-label">
              Category*
            </label>
            <Select
              id="inputCategory"
              options={categoryOptions}
              value={(formData?.basicInfo?.category?.label) ? formData?.basicInfo?.category : { label: formData?.basicInfo?.category, value: formData?.basicInfo?.category }}
              onChange={(e) => handleFieldChange(e, 'category')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputSpeciality" className="form-label">
              Speciality*
            </label>
            <Select
              id="inputSpeciality"
              options={specialityOptions}
              value={(formData?.basicInfo?.speciality?.label) ? formData?.basicInfo?.speciality : { label: formData?.basicInfo?.speciality, value: formData?.basicInfo?.speciality }}
              onChange={(e) => handleFieldChange(e, 'speciality')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputcertification" className="form-label">Select Certifications</label>
            <Select
              isMulti
              options={certificationOptions}
              value={
                formData?.basicInfo?.certifications?.length > 0
                  ? formData.basicInfo.certifications[0]?.label
                    ? formData.basicInfo.certifications
                    : formData.basicInfo.certifications.map((item) => ({ label: item, value: item }))
                  : []
              }
              onChange={(e) => handleFieldChange(e, 'certifications')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputState" className="form-label">State Licences</label>
            <Select
              isMulti
              options={stateLicenceOptions}
              value={
                formData?.basicInfo?.stateLicenses?.length > 0
                  ? formData.basicInfo.stateLicenses[0]?.label
                    ? formData.basicInfo.stateLicenses
                    : formData.basicInfo.stateLicenses.map((item) => ({ label: item, value: item }))
                  : []
              }
              onChange={(e) => handleFieldChange(e, 'stateLicenses')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputmailAddress" className="form-label">Mailing Address</label>
            <input type="text" className="form-control" id="inputmailAddress"
              placeholder='Enter Mailing Address'
              value={formData?.basicInfo?.mailingAddress || ""}
              name='mailingAddress'
              onChange={(e) => handleFieldChange(e, 'mailingAddress')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputcity" className="form-label">City</label>
            <input type="text" className="form-control" id="inputcity"
              placeholder='Enter City'
              value={formData?.basicInfo?.city}
              name='city'
              onChange={(e) => handleFieldChange(e, 'city')}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="inputZip" className="form-label">State</label>
            <Select
              options={stateLicenceOptions}
              value={(formData?.basicInfo?.state?.label) ? formData?.basicInfo?.state : { label: formData?.basicInfo?.state, value: formData?.basicInfo?.state }}
              onChange={(e) => handleFieldChange(e, 'state')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputcity" className="form-label">Zip</label>
            <input type="text" className="form-control" id="inputcity"
              placeholder='Enter ZipCode'
              value={formData?.basicInfo?.zipCode || ""}
              name='zipCode'
              onChange={(e) => handleFieldChange(e, 'zipCode')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputPhone" className="form-label">Mobile Phone*</label>
            <PhoneInput
              defaultCountry="us"
              value={formData?.basicInfo?.mobileNumber || ""}
              onChange={(phone) => {
                setFormData(values => ({
                  ...values,
                  basicInfo: {
                    ...values.basicInfo,
                    mobileNumber: phone
                  }
                }));
              }}
              inputStyle={{ width: '100%' }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputAlternativePhone" className="form-label">Alternative Mobile Phone</label>
            <PhoneInput
              defaultCountry="us"
              value={formData?.basicInfo?.alternateMobileNumber || ""}
              // onChange={(phone) => handleFieldChange(phone, 'alternateMobileNumber')}
              inputStyle={{ width: '100%' }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputtimetocall" className="form-label">Best Time To Call</label>
            <input type="text" className="form-control" id="inputtimetocall"
              placeholder='Enter Call Time'
              value={formData?.basicInfo?.bestTimeToCall || ""}
              name='bestTimeToCall'
              onChange={(e) => handleFieldChange(e, 'bestTimeToCall')}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="inputEmail" className="form-label">Email*</label>
            <input type="email" className="form-control" id="inputEmail"
              placeholder='Enter Email'
              value={formData?.basicInfo?.email || ""}
              name='email'
              onChange={(e) => handleFieldChange(e, 'email')}
              disabled
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="inputrefferedBy" className="form-label">Reffered By</label>
            <input type="text" className="form-control" id="inputrefferedBy"
              placeholder='Referred By'
              value={formData?.basicInfo?.referredBy || ""}
              name='referredBy'
              onChange={(e) => handleFieldChange(e, 'referredBy')}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="view-resume" className="form-label">View Resume</label>
            <br />
            <a className="view-resume-button" target="__blank" href={`https://bilkins-bucket.s3.amazonaws.com/${formData?.basicInfo?.resume}`}>
              Click here!
            </a>
          </div>
        </div>

        <hr />

        <div className="row g-3 my-2 form-div-start" >
          <h3>Educations</h3>
          {formData?.educations?.map((item, index) => (
            <>
              <div className="col-md-6" key={index}>
                <label htmlFor="inputSchoolName" className="form-label">School name*</label>
                <input type="text" className="form-control" id="inputSchoolName"
                  placeholder='Enter School Name'
                  name='schoolName'
                  value={item?.schoolName || ""}
                  onChange={(e) => handleEducationFieldChange(e, 'schoolName', index)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputSchoolType" className="form-label">School Type*</label>
                <Select
                  options={schoolTypeOptions}
                  value={(item?.schoolType?.label) ? item?.schoolType : { label: item?.schoolType, value: item?.schoolType }}
                  onChange={(e) => handleEducationFieldChange(e, 'schoolType', index)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputSchoolcity" className="form-label">City*</label>
                <input type="text" className="form-control" id="inputSchoolcity"
                  placeholder='Enter City'
                  name='schoolCity'
                  value={item?.schoolCity || ""}
                  onChange={(e) => handleEducationFieldChange(e, 'schoolCity', index)}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="inputSchoolState" className="form-label">State*</label>
                <Select
                  options={stateLicenceOptions}
                  value={(item?.schoolState?.label) ? item?.schoolState : { label: item?.schoolState, value: item?.schoolState }}
                  onChange={(e) => handleEducationFieldChange(e, 'schoolState', index)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputDegree" className="form-label">Degree/Diploma*</label>
                <input type="text" className="form-control" id="inputDegree"
                  placeholder='Enter Degree/Diploma'
                  name='degreeDiploma'
                  value={item?.degreeDiploma || ""}
                  onChange={(e) => handleEducationFieldChange(e, 'degreeDiploma', index)} />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputdateGraduated" className="form-label">Date Graduated* </label>
                <DatePicker
                  onChange={(e) => handleEducationFieldChange(e, 'graduateDate', index)}
                  value={item?.graduateDate || null}
                  className="form-control"
                  style={{ width: '100%' }}
                  placeholder='Enter Graduation Date'
                  format={'MM/DD/YYYY'}
                />
              </div>
              <div>
                <FaTrash style={{ color: "orangered" }} onClick={() => {
                  try {
                    let newData = { ...formData };
                    newData['educations'] = newData['educations']?.filter((item, idx) => idx !== index);
                    setFormData(newData);
                  } catch (error) {
                    console.error(error);
                  }
                }} />
              </div>
            </>
          ))}

          <div className="d-flex justify-content-between">
            <button variant="secondary" className='btn-add-more' onClick={handleAddEducation}>
              Add More
            </button>
          </div>
        </div>

        <hr />

        <div className="row g-3 my-2 form-div-start" >
          <h3>Certifications</h3>
          {formData?.certifications?.map((item, index) => (<>
            <div className="col-md-6" key={index}>
              <label htmlFor="inputCertficate1" className="form-label">Certificate Url*</label>
              <input type="text" className="form-control" id="inputCertficate1"
                placeholder='Enter Certificate Url'
                name="certificateURL"
                value={item?.certificateURL || ""}
                onChange={(e) => handleCertificationFieldChange(e, "certificateURL", index)}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="inputCertificateExpiry1" className="form-label">Expiration Date*</label>
              <DatePicker
                onChange={(e) => handleCertificationFieldChange(e, "expirationDate", index)}
                value={item?.expirationDate || null}
                className="form-control"
                style={{ width: '100%' }}
                placeholder='Enter Expiration Date'
                format={'MM/DD/YYYY'}
              />
            </div>

            <div>
              <FaTrash style={{ color: "orangered" }} onClick={() => {
                try {
                  let newData = { ...formData };
                  newData['certifications'] = newData['certifications']?.filter((item, idx) => idx !== index);
                  setFormData(newData);
                } catch (error) {
                  console.error(error);
                }
              }} />
            </div>
          </>
          ))}

          <div className="d-flex justify-content-between">
            <button variant="secondary" className='btn-add-more' onClick={handleAddCertification}>
              Add More
            </button>
          </div>
        </div>

        <hr />

        <div className="row g-3 my-2 form-div-start" >
          <h3>Licences</h3>
          {formData?.licences?.map((item, index) => (
            <>
              <div className="col-md-4">
                <label htmlFor="inputLicenceState" className="form-label">State*</label>
                <Select
                  options={stateLicenceOptions}
                  value={(item?.state?.label) ? item?.state : { label: item?.state, value: item?.state }}
                  onChange={(e) => handleLicenseFieldChange(e, "state", index)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputlicenceIssued" className="form-label">Issued Date*</label>
                <DatePicker
                  onChange={(e) => handleLicenseFieldChange(e, "issuedDate", index)}
                  value={item?.issuedDate || null}
                  className="form-control"
                  style={{ width: '100%' }}
                  placeholder='Enter Issued Date'
                  format={'MM/DD/YYYY'}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputlicenceExpiry1" className="form-label">Expiration Date*</label>
                <DatePicker
                  onChange={(e) => handleLicenseFieldChange(e, "expirationDate", index)}
                  value={item?.expirationDate || null}
                  className="form-control"
                  style={{ width: '100%' }}
                  placeholder='Enter Expiration Date'
                  format={'MM/DD/YYYY'}
                />
              </div>

              <div>
                <FaTrash style={{ color: "orangered" }} onClick={() => {
                  try {
                    let newData = { ...formData };
                    newData['licences'] = newData['licences']?.filter((item, idx) => idx !== index);
                    setFormData(newData);
                  } catch (error) {
                    console.error(error);
                  }
                }} />
              </div>
            </>
          ))}
          <div className="d-flex justify-content-between">
            <button variant="secondary" className='btn-add-more' onClick={handleAddLicence}>
              Add More
            </button>
          </div>
        </div>

        <hr />

        <div className="row g-3 my-2 form-div-start" >
          <h3>Experiences</h3>
          {formData?.experiences?.map((item, index) => (
            <>
              <div className="col-md-6 ">
                <label htmlFor="inputSpeciality1" className="form-label">Speciality*</label>
                <input type="text" className="form-control" id="inputSpeciality1"
                  placeholder='Enter Speciality'
                  name="speciality"
                  value={item?.speciality || ""}
                  onChange={(e) => handleExperienceFieldChange(e, "speciality", index)} />
              </div>

              <div className="col-md-6 ">
                <label htmlFor="inputExperience1" className="form-label">Years Of Experience*</label>
                <input type="text" className="form-control" id="inputExperience1"
                  placeholder='Enter Years of Experience'
                  name="yearOFExperience"
                  value={item?.yearOFExperience || ""}
                  onChange={(e) => handleExperienceFieldChange(e, "yearOFExperience", index)} />
              </div>
              <div>
                <FaTrash style={{ color: "orangered" }} onClick={() => {
                  try {
                    let newData = { ...formData };
                    newData['experiences'] = newData['experiences']?.filter((item, idx) => idx !== index);
                    setFormData(newData);
                  } catch (error) {
                    console.error(error);
                  }
                }} />
              </div>
            </>
          ))}
          <div className="d-flex justify-content-between">
            <button variant="secondary" className='btn-add-more' onClick={handleAddExperience}>
              Add More
            </button>
          </div>

          <div className="col-md-6" style={{ marginRight: "4rem" }}>
            <label htmlFor="drugScreen" className="form-label">Are you willing to submit to a drug screen?*</label>
          </div>
          <div className="col-md-2">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="drugScreen" id="inputDrugScreenYes1" value={true} checked={formData?.drugScreen?.toString() === 'true' ? true : false} onChange={(e) => handleFieldChange(e, 'drugScreen')} />
              <label className="form-check-label" htmlFor="inputDrugScreenYes1">Yes</label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="drugScreen" id="inputDrugScreenNo1" value={false} checked={formData?.drugScreen?.toString() === 'true' ? false : true} onChange={(e) => handleFieldChange(e, 'drugScreen')} />
              <label className="form-check-label" htmlFor="inputDrugScreenNo1">No</label>
            </div>
          </div>
          <div className="col-md-6" style={{ marginRight: "4rem" }}>
            <label htmlFor="inputBackgroundCheck" className="form-label">Are you willing to submit to a criminal background check?*</label>
          </div>
          <div className="col-md-2">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="criminalBackgroundCheck" id="inputBackgroundCheckYes" value={true} checked={formData?.criminalBackgroundCheck?.toString() === 'true' ? true : false} onChange={(e) => handleFieldChange(e, 'criminalBackgroundCheck')} />
              <label className="form-check-label" htmlFor="inputBackgroundCheckYes">Yes</label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="criminalBackgroundCheck" id="inputBackgroundCheckNo" value={false} checked={formData?.criminalBackgroundCheck?.toString() === 'true' ? false : true} onChange={(e) => handleFieldChange(e, 'criminalBackgroundCheck')} />
              <label className="form-check-label" htmlFor="inputBackgroundCheckNo">No</label>
            </div>
          </div>
          <div className="col-md-6 " style={{ marginRight: "4rem" }}>
            <label htmlFor="inputLimitations" className="form-label">Do you have any limitations that would restrict you from performing essential functions in the position for which you are applying?*</label>
          </div>
          <div className="col-md-2">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="actionLimitaions" id="inputLimitationsYes" value={true} checked={formData?.actionLimitaions?.toString() === 'true' ? true : false} onChange={(e) => handleFieldChange(e, 'actionLimitaions')} />
              <label className="form-check-label" htmlFor="inputLimitationsYes">Yes</label>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-check">
              <input className="form-check-input" type="radio" name="actionLimitaions" id="inputLimitationsNo" value={false} checked={formData?.actionLimitaions?.toString() === 'true' ? false : true} onChange={(e) => handleFieldChange(e, 'actionLimitaions')} />
              <label className="form-check-label" htmlFor="inputLimitationsNo">No</label>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label htmlFor="inputSpeciality1" className="form-label">If YES, please provide a detailed explanation.</label>
            <textarea style={{ resize: "none" }} className="form-control" id="inputSpeciality1" rows="4" name='limitationDescription' value={formData?.limitationDescription} onChange={(e) => handleFieldChange(e, 'limitationDescription')}></textarea>
          </div>
        </div>

        <hr />

        <div className="row g-3 my-2 form-div-start" >
          <h3>Past Employment</h3>
          {formData?.prevEmployements?.map((item, index) => (
            <>
              <div className="col-md-6 ">
                <label htmlFor="inputFacility1" className="form-label">Facility*</label>
                <input type="text" className="form-control" id="inputFacility1"
                  placeholder='Enter Facility'
                  name="facility"
                  value={item?.facility || ''}
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "facility", index)} />
              </div>

              <div className="col-md-6 ">
                <label htmlFor="inputDepartment1" className="form-label">Department*</label>
                <input type="text" className="form-control" id="inputDepartment1"
                  placeholder='Enter Department'
                  name="department"
                  value={item?.department || ''}
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "department", index)} />
              </div>
              <div className="col-md-6 ">
                <label htmlFor="inputSupervisor1" className="form-label">Supervisor's Name*</label>
                <input type="text" className="form-control" id="inputSupervisor1"
                  placeholder="Enter Supervisor's Name"
                  name="supervisiorName"
                  value={item?.supervisiorName || ''}
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "supervisiorName", index)} />
              </div>

              <div className="col-md-6">
                <label htmlFor="inputStartDate1" className="form-label">Start Date*</label>
                <DatePicker
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "startDate", index)}
                  value={item?.startDate || null}
                  className="form-control"
                  style={{ width: '100%' }}
                  placeholder='Enter Start Date'
                  format={'MM/DD/YYYY'}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="inputStartDate1" className="form-label">End Date*</label>
                <DatePicker
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "endDate", index)}
                  value={item?.endDate || null}
                  className="form-control"
                  style={{ width: '100%' }}
                  placeholder='Enter End Date'
                  format={'MM/DD/YYYY'}
                />
              </div>

              <div className="col-md-6  ">
                <label htmlFor="inputCity1" className="form-label">City*</label>
                <input type="text" className="form-control" id="inputCity1"
                  placeholder='Enter City'
                  name="city"
                  value={item?.city || ''}
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "city", index)} />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputState1" className="form-label">State*</label>
                <Select
                  options={stateLicenceOptions}
                  value={(item?.state?.label) ? item?.state : { label: item?.state, value: item?.state }}
                  onChange={(e) => handlePrevEmploymentFieldChange(e, 'state', index)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputHours1" className="form-label">Hours*</label>
                <input type="text" className="form-control" id="inputHours1"
                  placeholder='Enter Hours'
                  name="hours"
                  value={item?.hours || ''}
                  onChange={(e) => handlePrevEmploymentFieldChange(e, "hours", index)} />
              </div>
              <div>
                <FaTrash style={{ color: "orangered" }} onClick={() => {
                  try {
                    let newData = { ...formData };
                    newData['prevEmployements'] = newData['prevEmployements']?.filter((item, idx) => idx !== index);
                    setFormData(newData);
                  } catch (error) {
                    console.error(error);
                  }
                }} />
              </div>
            </>
          ))}

          <div className="d-flex justify-content-between">
            <button variant="secondary" className='btn-add-more' onClick={handleAddPrevEmployment}>
              Add More
            </button>
          </div>

          <div className='col-md-12'>
            <p style={{ fontSize: "12px" }}>I certify that all statements made in this application are true to the best of my knowledge. I understand that all falsification or misleading information given in my application may result in the termination of my employment with Fusion Medical Staffing. Furthermore, I understand that my professional conduct and clinical performance is directly related to my ability to be placed on assignments for Fusion Medical Staffing and that I will adhere to all expectations set forth in the employee handbook. I authorization Fusion Medical Staffing to verify the information I have provided, to contact references, and to conduct a criminal background check concerning my qualifications and past employment record. I understand that nothing contained in this application is intended to create an employment contract, either verbal or written, with Fusion Medical Staffing or its clients. Furthermore, I understand that in the event of my employment, it is "at will" and Fusion Medical Staffing or I may terminate my employment at any time with or without notice and with or without case.</p>
          </div>
          <div className="col-md-12">
            <label htmlFor="inputTerms" className="form-label">Terms*</label>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="inputTerms" name='termsConditionAgreed' checked={formData?.termsConditionAgreed} onChange={(e) => handleFieldChange(e, 'termsConditionAgreed')} />
              <label className="form-check-label" htmlFor="inputTerms">I agree to the terms</label>
            </div>
          </div>
        </div>

        <div className="col-4 d-flex justify-content-space-between">
          <button className="edit-application-button" onClick={handleFormSubmission}>
            Edit Application
          </button>
          <button className="add-benefit-button d-flex gap-2 align-content-center mx-3" onClick={() => {
            navigate('/applicants');
          }}>
            Cancel
          </button>
        </div>
      </div >
    </>
  );
};
export default ViewApplication;
