import React, { useState, useEffect } from "react";
import "./auth.css";
import logo from "../../Assets/logos/logo.svg";
import login from "../../Assets/bg/login.webp";
import { Link, useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import Instance from "../../AxiosConfig";
import { showErrorAlert } from "../../globalConstant";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const checkToken = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await Instance.get('/validateToken', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        navigate('/dashboard')
      }
    } catch (error) {
      // No token present means login is required
    }
  }

  useEffect(() => {
    checkToken();
  }, []);

  const loginUser = async (email, password) => {
    try {
      const response = await Instance.post("/cms-login", {
        email, password
      });

      if (response.status === 200) {
        const { responseData } = response?.data;
        if (responseData) {
          localStorage.setItem("loginUserData",JSON.stringify(responseData))
          localStorage.setItem('token', responseData.token);
          localStorage.setItem("dashboard", true)
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(error?.response?.data?.message || "An error occurred. Please try again later.");
    }
    setLoading(false);
  }

  const ReDirect = () => {
    setLoading(true);
    if (rememberMe) {
      localStorage.setItem('rememberedEmail', email);
      localStorage.setItem('rememberedPassword', password);
    } else {
      localStorage.removeItem('rememberedEmail');
      localStorage.removeItem('rememberedPassword');
    }

    if (email.match(emailregex) && password.length >= 8) {
      loginUser(email, password);
    }
    else {
      if (!email.match(emailregex)) {
        showErrorAlert("Please enter a valid email address. The format should be like 'example@example.com'.");
      }
      setLoading(false);
    }
  };

  const toggleRememberPassword = () => {
    setRememberMe(!rememberMe);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    const rememberedPassword = localStorage.getItem('rememberedPassword');

    if (rememberedEmail && rememberedPassword) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setRememberMe(true);
    }
  }, []);

  return (
    <div>
      <div className="login-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="login-input-section">
                <div className="login-input-header">
                  <img src={logo} alt="logo" />
                  <p>Enter your email and password to Sign In</p>
                </div>
                <br />
                <div className="login-input-field-section">
                  <div className="my-3 form-inputs-section">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <br />
                    <input
                      type="email"
                      className="form-input-control"
                      placeholder="Enter Email"
                      id="email"
                      aria-describedby="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="my-3 form-inputs-section"
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <br />
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-input-control"
                      placeholder="Enter Password"
                      id="password"
                      aria-describedby="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      className="btn-password-show-hide"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FiEye /> : <FiEyeOff />}
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-between my-4">
                    <div className="form-check remember-password-toggle">
                      <label
                        className="form-check-label toggle-switch"
                        htmlFor="rememberPassword"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={rememberMe}
                          onChange={toggleRememberPassword}
                          id="rememberPassword"
                        />
                        <span className="toggle-slider round"></span>
                      </label>

                      <span className="remember-password">
                        Remember Password
                      </span>
                    </div>
                    <Link to="/forgot-password" className="forgot-password-link">
                      Forgot Password?
                    </Link>
                  </div>

                  <button
                    type="button"
                    className="btn-login"
                    onClick={ReDirect}
                    disabled={loading}
                  >
                    {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Login'}
                  </button>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-img-section">
                <img src={login} alt="login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
