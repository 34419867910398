import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import Sidebar from "../../Layout/Header/Sidebar";
import Header from "../../Layout/Header/Header";
import JobList from "../../Components/Jobs/JobsList";

const JobPortalPage = () => {

  return (
    <>
      <Sidebar />
      <Header />
      <div className="main-wrapper">
        <JobList />
      </div>
    </>
  );
};

export default JobPortalPage;
