import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "usersSlice",
  initialState: {
    users: [],
  },
  reducers: {
    addAllUsers: (state, action) => {
      state.users = action.payload;
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter(
        (item) => item._id.toString() !== action.payload?.toString()
      );
    },
    addNewUser: (state, action) => {
      state.users.push(action.payload);
    },
    editUser: (state, action) => {
      let index = state.users.findIndex(
        (item) => item._id?.toString() === action.payload._id?.toString()
      );
      if (index !== -1) state.users[index] = action.payload;
    },
  },
});

export const { addAllUsers, deleteUser, addNewUser, editUser } =
  usersSlice.actions;

export default usersSlice.reducer;
