import React from 'react';
import "./setting.css";
import { Switch, Button, Table, Tooltip, Dropdown, Menu } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, AimOutlined, InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import profile from "../../Assets/user/edit-profile.png";

const profileMenu = (
    <Menu>
        <Menu.Item key="edit-profile">
            Edit Profile
        </Menu.Item>
    </Menu>
);

const SettingList = () => {

    const notificationColumns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => (
                <span>
                    {text}
                    <Tooltip title={`This is ${text}`}>
                        <InfoCircleOutlined style={{ marginLeft: 8, color: '#ce1b28', cursor: "pointer" }} />
                    </Tooltip>
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (checked) => <Switch defaultChecked={checked} />,
        },
        {
            title: 'Browser',
            dataIndex: 'browser',
            key: 'browser',
            render: (checked) => <Switch defaultChecked={checked} />,
        },
        {
            title: 'App',
            dataIndex: 'app',
            key: 'app',
            render: (checked) => <Switch defaultChecked={checked} />,
        },
    ];

    const notificationData = [
        {
            key: '1',
            type: 'Billing Notifications',
            email: false,
            browser: true,
            app: false,
        },
        {
            key: '2',
            type: 'Newsletter',
            email: false,
            browser: false,
            app: true,
        },
        {
            key: '3',
            type: 'New User',
            email: false,
            browser: false,
            app: false,
        },
        {
            key: '4',
            type: 'In-App Messaging',
            email: true,
            browser: false,
            app: false,
        },
    ];

    return (
        <div className="settinglist-layout">
            <div className="profile-outline">
                <div className='ellipese-position'>
                    <Dropdown overlay={profileMenu} trigger={['click']}>
                        <div className="ellipese-icon">
                            <MoreOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                        </div>
                    </Dropdown>
                </div>
                <div className="profile-header">
                    <img src={profile} alt="profile" />
                    <div className='profile-tags-coontent'>
                        <h2>Kiran</h2>
                        <p>@Kiran Dzinr</p>
                        <p><span style={{ color: "#000" }}><AimOutlined /></span> <b>HSR Bangalore</b></p>
                    </div>
                </div>
            </div>

            <div className="settings-group">
                <div className="section-title">Security</div>
                <div className="settings-item">
                    <div className='setting-content-width'>
                        <label>Email Address</label>
                        <br />
                        <span>kiran@gmail.com</span>
                    </div>
                    <div className='setting-ticks-width'>
                        <CheckCircleOutlined style={{ color: '#11B981', marginRight: '4px' }} /><span>Verified</span>
                    </div>
                    <div>
                        <Button className='setting-button'>Change Email</Button>
                    </div>
                </div>
                <div className="settings-item">
                    <div className='setting-content-width'>
                        <label>Password</label>
                        <br />
                        <span>********</span>
                    </div>
                    <div className='setting-ticks-width'>
                        <CheckCircleOutlined style={{ color: '#11B981', marginRight: '4px' }} /><span>Verified</span>
                    </div>
                    <div>
                        <Button className='setting-button'>Reset Password</Button>
                    </div>
                </div>

                <div className="settings-item">
                    <div className='setting-content-width'>
                        <label>Google Authenticator (Recommended)</label>
                        <br />
                        <span>Protect your account and transactions.</span>
                    </div>
                    <div className='setting-ticks-width'>
                        <Switch defaultChecked />
                    </div>
                    <div>
                        <Button className='setting-button'>Change</Button>
                        <Button className='setting-button'>Remove</Button>
                    </div>
                </div>

                <div className="settings-item">
                    <div className='setting-content-width'>
                        <label>Phone Number Verification</label>
                        <br />
                        <span>+1-202-555-0116</span>
                    </div>
                    <div className='setting-ticks-width'>
                        <CloseCircleOutlined style={{ color: '#ff0000', marginRight: '4px' }} />
                        <span>Not linked</span>
                    </div>
                    <div>
                        <Button className='setting-button'>Verify</Button>
                    </div>
                </div>
            </div>

            <div className="switch-group">
                <div className="section-title">Notifications</div>
                <Table
                    columns={notificationColumns}
                    dataSource={notificationData}
                    pagination={false}
                />
            </div>
        </div>
    );
};

export default SettingList;
