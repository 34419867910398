import React from "react";
import Sidebar from '../../Layout/Header/Sidebar';
import Header from '../../Layout/Header/Header';
import GeneralApplicantsList from "../../Components/GeneralApplicants/GeneralApplicantsList.jsx";

const GeneralApplicantPage = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <div className="main-wrapper">
                <GeneralApplicantsList />
            </div>
        </>
    )
}

export default GeneralApplicantPage;