import React, { useState } from "react";
import "./gallery.css";
import { Input, DatePicker, Spin } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Select from "react-select";
import Instance from "../../AxiosConfig";
import { useEffect } from "react";
import { addAllMedia, deleteMedia } from "../../features/mediaSlice";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { showErrorAlert, showSuccessAlert } from "../../globalConstant";

const sourceOptions = [
  { value: "Quick Application", label: "Quick Application" },
  { value: "Full Application", label: "Full Application" },
  { value: 'User Profile', label: 'User Profile' },
  { value: 'Join Quick Application', label: 'Join Us Quick Application' },
  { value: 'Join Full Application', label: 'Join Us Full Application' }
];

const GalleryList = () => {
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState("grid");
  const mediaData = useSelector(state => state.medias.media);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [fileterParameters, setFilterParameters] = useState({});

  const fetchMediaData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get('/getAllMedia', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response?.status === 200) {
        let sortedData = response?.data?.allMedia?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAllData(sortedData);
        dispatch(addAllMedia(sortedData));
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured')
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchMediaData();
  }, []);

  const handleDeleteMedia = async (data) => {
    try {
      setIsLoading(true);
      const response = await Instance.delete(`/deleteMediaById/${data._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.status === 200) {
        showSuccessAlert('Media Deleted Successfully');
        dispatch(deleteMedia(data._id));
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured')
    } finally {
      setIsLoading(false);
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleFilter = () => {
    let filteredData = [...allData];
    if (fileterParameters?.date) {
      filteredData = filteredData?.filter((item) => {
        const itemDate = formatDate(item?.createdAt);
        const filterDate = formatDate(fileterParameters?.date);
        return itemDate === filterDate;
      });
    }

    if (fileterParameters?.sourcePage && fileterParameters?.sourcePage.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.sourcePage?.findIndex((d) => d.value === item?.uploadedFrom);
        if (exist !== -1) return 1;
        return 0;
      })
    }
    dispatch(addAllMedia(filteredData));
  };


  useEffect(() => {
    if (fileterParameters?.searchText === '') {
      dispatch(addAllMedia(allData));
    };
    let filteredData = allData?.filter(item => JSON.stringify(item)?.toLowerCase().includes(fileterParameters?.searchText?.toLowerCase()));
    dispatch(addAllMedia(filteredData));
  }, [fileterParameters?.searchText]);

  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <div className="gallery-section">
        <div className="filter-row">
          <div className="select-options">
            <Select
              options={sourceOptions}
              isMulti
              placeholder="All Source Pages"
              styles={{ ...customStyles, menuPortal: document.body }}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['sourcePage'] = e;
                setFilterParameters(newData);
              }}
            />
            <DatePicker
              className="custom-date-picker mx-2"
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['date'] = e;
                setFilterParameters(newData);
              }}
              format={'MM/DD/YYYY'}
            />
          </div>
          <div className="search-table-container">
            <Input
              placeholder="Search..."
              value={fileterParameters?.searchText}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['searchText'] = e.target.value;
                setFilterParameters(newData);
              }}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <button onClick={handleFilter}>
              <FilterOutlined /> <span>Filter</span>
            </button>
            {/* <button style={{ marginRight: "8px" }} onClick={() => setViewMode("grid")} className={viewMode === "grid" ? "active" : ""}>
            <AppstoreOutlined />
          </button>
          <button onClick={() => setViewMode("list")} className={viewMode === "list" ? "active" : ""}>
            <UnorderedListOutlined />
          </button> */}
          </div>
        </div>
        <div className={`gallery-content row ${viewMode === "list" ? "list-view" : "grid-view"}`}>
          {mediaData.map((data, index) => (
            <div className="img_card">
              <div class="iframe_container">
                <iframe src={`https://bilkins-bucket.s3.amazonaws.com/${data.content}`} class="iframe_media" title="Resume"></iframe>
              </div>

              <h3>{data?.originalName ? data?.originalName : 'N/A'}</h3>
              <Input
                placeholder="Source Page"
                className="form-control mb-3"
                suffix={<span>Source Page</span>}
                value={data?.uploadedFrom}
              />
              <div style={{ width: '60%', display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                <button className="imgcard_Button"><a href={`https://bilkins-bucket.s3.amazonaws.com/${data.content}`} target="__blank">View</a></button>
                <button className="imgcard_Button"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure",
                      text: "You want to Delete?",
                      showCancelButton: true,
                      confirmButtonColor: "#555",
                      cancelButtonColor: "#ce1b28",
                      confirmButtonText: "Yes, Delete!"
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDeleteMedia(data)
                      }
                    })
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GalleryList;
