import React from "react";
import TotalCounts from "./TotalCounts";
import GraphMap from "./GraphMap";

const Dashboard = () => {
  return (
    <>
      <div className="title-home-dashboard">
        <h3>Dashboard</h3>
      </div>
      <TotalCounts />
      <GraphMap />
    </>
  );
};

export default Dashboard;
