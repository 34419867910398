import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import {
  SearchOutlined,
  FilterOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Select from "react-select";
import Instance from "../../AxiosConfig";
import { useSelector, useDispatch } from "react-redux";
import {
  addAllApplications,
  deleteApplication,
} from "../../features/applicantsSlice";
import "./applicants.css";
import { useNavigate } from "react-router-dom";
import "./applicants.css";
import Swal from "sweetalert2";
import { Spin } from "antd";
import { dateFormat, showErrorAlert, showSuccessAlert } from "../../globalConstant";

const searchCriteriaOptions = [{ value: "JobId", label: "JobId" }];

const ApplicantList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState([]);
  const [fileterParameters, setFilterParameters] = useState({});

  const applicationData = useSelector(
    (state) => state?.applications?.applications
  );

  const fetchApplicantsData = async () => {
    try {
      setIsLoading(true);
      const response = await Instance.get("/getAllApplication", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if ((response.status = 200 && response?.data?.applications)) {
        let data = response?.data?.applications?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setAllData(data);
        dispatch(addAllApplications(data));
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || "An error occured");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicantsData();
  }, []);

  const columns = [
    {
      title: "Applicant Name",
      sorter: (a, b) =>
        a?.basicInfo?.firstName.localeCompare(b?.basicInfo?.firstName),
      render: (text, item) => (
        <div>
          {`${item?.basicInfo?.firstName} ${item?.basicInfo?.lastName}`}
        </div>
      ),
    },
    {
      title: "Email",
      sorter: (a, b) => a?.basicInfo?.email.localeCompare(b?.basicInfo?.email),
      render: (text, item) => <div>{`${item?.basicInfo?.email}`}</div>,
    },
    {
      title: "JobId",
      sorter: (a, b) => {
        return a?.manualJobId - b?.manualJobId
      },
      render: (text, item) => <div>{item?.manualJobId ? item?.manualJobId : "N/A"}</div>,
    },
    {
      title: "Status",
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      render: (text, item) => <div>{`${item?.status}`}</div>,
    },
    {
      title: "Source Page",
      sorter: (a, b) => a?.sourcePage.localeCompare(b?.sourcePage),
      render: (text, item) => <div>{`${item?.sourcePage}`}</div>,
    },
    {
      title: "Submission Date",
      sorter: (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt),
      render: (text, item) => (
        <div>
          {new Date(item?.createdAt).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
        </div>
      ),
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      render: (text, record) => (
        <div
          className="view--button"
          onClick={() => {
            navigate(`/view-application/${record._id}`);
          }}
        >
          View
        </div>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <DeleteOutlined
          key={`delete-${record._id}`}
          className="delete-button-table"
          onClick={() => {
            Swal.fire({
              title: "Are you sure",
              text: "You want to Delete?",
              showCancelButton: true,
              confirmButtonColor: "#555",
              cancelButtonColor: "#ce1b28",
              confirmButtonText: "Yes, Delete!",
            }).then((result) => {
              if (result.isConfirmed) {
                handleDelete(record._id);
              }
            });
          }}
        />
      ),
    },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  const handleFilter = () => {
    let filteredData = [...allData];
    if (fileterParameters["Date Posted"]) {
      const filterDays = fileterParameters["Date Posted"].value;
      if (filterDays && filterDays !== -1) {
        const currentDate = new Date();
        filteredData = filteredData?.filter((item) => {
          const itemDate = new Date(item?.createdAt);
          return (currentDate - itemDate) / (1000 * 60 * 60 * 24) <= filterDays;
        });
      }
    }

    if (fileterParameters?.pages && fileterParameters?.pages.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.pages?.findIndex(
          (d) => d.value === item?.sourcePage
        );
        if (exist !== -1) return 1;
        return 0;
      });
    }

    if (
      fileterParameters?.selectedRows &&
      fileterParameters?.selectedRows.length !== 0
    ) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.selectedRows.findIndex(
          (d) => d?.toString() === item?._id?.toString()
        );
        if (exist !== -1) return 1;
        return 0;
      });
    }

    dispatch(addAllApplications(filteredData));
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await Instance.delete(`/deleteApplication/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        showSuccessAlert("Application Deleted Successfully!");
        dispatch(deleteApplication(id));
        let curData = allData?.filter(
          (item) => item?._id?.toString() !== id?.toString()
        );
        setAllData(curData);
      }
    } catch (error) {
      showErrorAlert("Failed to Delete Application");
    } finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fileterParameters?.searchText === "") {
      dispatch(addAllApplications(allData));
    } else if (selectedSearchCriteria?.value === "JobId") {
      let filteredData = allData?.filter(
        (item) =>
          parseInt(item?.manualJobId) ===
          parseInt(fileterParameters?.searchText?.trim())
      );
      dispatch(addAllApplications(filteredData));
    } else {
      let filteredData = allData?.filter((item) =>
        JSON.stringify(item)
          ?.toLowerCase()
          .includes(fileterParameters?.searchText?.toLowerCase())
      );
      dispatch(addAllApplications(filteredData));
    }
  }, [fileterParameters?.searchText]);

  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <div className="main-title-all">
        <span>Applicants</span>
      </div>
      <div className="post-list-section">
        <div className="filter-row d-flex justify-content-end align-item-center">
          <div className="search-table-container">
            <Input
              placeholder="Search..."
              value={fileterParameters?.searchText}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData["searchText"] = e.target.value;
                setFilterParameters(newData);
              }}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <Select
              options={searchCriteriaOptions}
              onChange={(e) => {
                setSelectedSearchCriteria(e);
              }}
              value={selectedSearchCriteria}
              placeholder="Search Criteria"
              styles={{ ...customStyles, menuPortal: document.body }}
              className="me-2"
              isClearable
            />
            <button onClick={handleFilter}>
              <FilterOutlined /> <span>Filter</span>
            </button>
          </div>
        </div>

        <div className="file-count my-3">
          {" "}
          <span>All ({applicationData.length})</span> |{" "}
          <span className="sp-r">Read ({applicationData.length})</span> |{" "}
          <span className="sp-r">Trash ({applicationData.length})</span>
        </div>

        <div className="table-list">
          <Table
            columns={columns}
            dataSource={
              filteredData.length > 0 ? filteredData : applicationData
            }
            rowKey={(data) => data?._id}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {
                setFilterParameters((prevData) => ({
                  ...prevData,
                  ["selectedRows"]: selectedRowKeys,
                }));
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ApplicantList;
