import React, { useEffect, useState } from "react";
import "./homedashboard.css";
import jobs from "../../Assets/icons/jobs.svg";
import applicants from "../../Assets/icons/applicants.svg";
import post from "../../Assets/icons/post.svg";
import user from "../../Assets/icons/user.svg";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
import { showErrorAlert } from "../../globalConstant";
import { Spin } from "antd";

const TotalCounts = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [postCount, setPostCount] = useState(0);
  const [jobCount, setJobCount] = useState(0);
  const [applicationCount, setApplicationCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);

  const data = [
    { title: "Total Jobs", count: jobCount, icon: jobs, link: "/jobs" },
    { title: "Total Applicants", count: applicationCount, icon: applicants, link: "/applicants" },
    { title: "Total Posts", count: postCount, icon: post, link: "/post" },
    { title: "Total Users", count: usersCount, icon: user, link: "/user" }
  ];

  const handleCardClick = (link) => {
    navigate(link);
  };

  const fetchPostCount = async () => {
    try {
      const response = await Instance.get('/getAllPost', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        if (response?.data?.posts?.length) setPostCount(response?.data?.posts?.length);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  }

  const fetchJobCount = async () => {
    try {
      const response = await Instance.get('/getAllJobs', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        if (response?.data?.jobs?.length) setJobCount(response?.data?.jobs?.length);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  }

  const fetchApplicantsCount = async () => {
    try {
      const response = await Instance.get('/getAllApplication', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        if (response?.data?.applications?.length) setApplicationCount(response?.data?.applications?.length);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  }

  const fetchUsersCount = async () => {
    try {
      const response = await Instance.get('/getAllUsers', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        if (response?.data?.users?.length) setUsersCount(response?.data?.users?.length);
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchPostCount();
      await fetchJobCount();
      await fetchApplicantsCount();
      await fetchUsersCount();
      setIsLoading(false);
    }
    fetchData();
  }, [])

  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <div className="row">
        {data.length === 0 ? (
          <div className="col-lg-12">
            <p>No data available</p>
          </div>
        ) : (data?.map((item, index) => (
          <div className="col-lg-6 mb-4" key={index}>
            <div className="total-count-cards" onClick={() => handleCardClick(item.link)}>
              <div className="total-content">
                <div className="icon-total-dashboard">
                  <img src={item?.icon} alt={item?.title} />
                </div>
                <div className="title-total">
                  <h3>{item?.title}</h3>
                  <span>{item?.count}</span>
                </div>
              </div>
            </div>
          </div>
        ))
        )}
      </div>
    </>
  );
};

export default TotalCounts;
