import { configureStore } from '@reduxjs/toolkit';
import jobsSlice from '../features/jobsSlice';
import postsSlice from '../features/postsSlice';
import generalApplicantsSlice from '../features/generalApplicantsSlice';
import applicantsSlice from '../features/applicantsSlice';
import usersSlice from '../features/usersSlice';
import notificationSlice from '../features/notificationSlice';
import mediaSlice from '../features/mediaSlice';

export const store = configureStore({
    reducer: {
        jobs: jobsSlice,
        posts: postsSlice,
        generalApplications: generalApplicantsSlice,
        applications: applicantsSlice,
        users: usersSlice,
        notifications: notificationSlice,
        medias: mediaSlice,
    }
});
