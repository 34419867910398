import React from 'react';
import "./setting.css"
import SettingList from './SettingList';


const Setting = () => {

  return (
    <>
      <SettingList />
    </>
  )
}
export default Setting;