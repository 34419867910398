import React, { useState } from "react";
import { Input, Menu } from "antd";
import { SearchOutlined, MailOutlined, PauseCircleFilled } from '@ant-design/icons';
import Select from "react-select";
import user from "../../Assets/user/user1.png"
const sortByOptions = [
    { value: "ThisWeek", label: "This Week" },
    { value: "LastWeek", label: "Last Week" },
    { value: "LastMonth", label: "Last Month" },
];

const priorityOptions = [
    { value: "newTickets", label: <><PauseCircleFilled style={{ color: '#3B8AFF', backgroundColor: '#3B8AFF', borderRadius: "50%", width: "12px", height: "12px" }} /> <span style={{ fontSize: "14px" }}>New Tickets</span></> },
    { value: "ongoingTickets", label: <><PauseCircleFilled style={{ color: '#FAC885', backgroundColor: '#FAC885', borderRadius: "50%", width: "12px", height: "12px" }} /> <span style={{ fontSize: "14px" }}>Ongoing Tickets</span></> },
    { value: "resolvedTickets", label: <><PauseCircleFilled style={{ color: '#54C104', backgroundColor: '#54C104', borderRadius: "50%", width: "12px", height: "12px" }} /> <span style={{ fontSize: "14px" }}>Resolved Tickets</span></> },
];

const AllTickets = () => {
    return (
        <>
            <div className="Ticket-overview">
                <div className="border-bottom">
                    <div className="d-flex justify-content-between ">
                        <div className="d-flex gap-2">
                            <PauseCircleFilled style={{ color: '#FAC885', width: "12px", height: "12px", backgroundColor: '#FAC885', borderRadius: "50%" }} />
                            <h2>Ticket# 2023-CS123</h2>
                        </div>
                        <div>
                            <p>Posted at 12:45am</p>
                        </div>
                    </div>
                    <h3>How To Apply For a job?</h3>
                    <p>Could you please provide guidance or instructions on how to proceed with the application process? I would greatly appreciate any assistance or clarification you can offer.</p>
                </div>
                <div className="d-flex justify-content-between pt-2">
                    <div className="d-flex gap-2 align-items-center">
                        <img src={user} style={{ width: "28px", height: "28px" }} alt="" />
                        <span>Kiran</span>
                    </div>
                    <div>
                        <a href="/">Open Ticket</a>
                    </div>
                </div>
            </div>
            <div className="Ticket-overview">
                <div className="border-bottom">
                    <div className="d-flex justify-content-between ">
                        <div className="d-flex gap-2">
                            <PauseCircleFilled style={{ color: '#FAC885', width: "12px", height: "12px", backgroundColor: '#FAC885', borderRadius: "50%" }} />
                            <h2>Ticket# 2023-CS123</h2>
                        </div>
                        <div>
                            <p>Posted at 12:45am</p>
                        </div>
                    </div>
                    <h3>How To Apply For a job?</h3>
                    <p>Could you please provide guidance or instructions on how to proceed with the application process? I would greatly appreciate any assistance or clarification you can offer.</p>
                </div>
                <div className="d-flex justify-content-between pt-2">
                    <div className="d-flex gap-2 align-items-center">
                        <img src={user} style={{ width: "28px", height: "28px" }} alt="" />
                        <span>Kiran</span>
                    </div>
                    <div>
                        <a href="/">Open Ticket</a>
                    </div>
                </div>
            </div>
        </>
    )
};

const NewTickets = () => {
    return (
        <>
            <div className="Ticket-overview">
                <div className="border-bottom">
                    <div className="d-flex justify-content-between ">
                        <div className="d-flex gap-2">
                            <PauseCircleFilled style={{ color: '#FAC885', width: "12px", height: "12px", backgroundColor: '#FAC885', borderRadius: "50%" }} />
                            <h2>Ticket# 2023-CS123</h2>
                        </div>
                        <div>
                            <p>Posted at 12:45am</p>
                        </div>
                    </div>
                    <h3>How To Apply For a job?</h3>
                    <p>Could you please provide guidance or instructions on how to proceed with the application process? I would greatly appreciate any assistance or clarification you can offer.</p>
                </div>
                <div className="d-flex justify-content-between pt-2">
                    <div className="d-flex gap-2 align-items-center">
                        <img src={user} style={{ width: "28px", height: "28px" }} alt="" />
                        <span>Kiran</span>
                    </div>
                    <div>
                        <a href="/">Open Ticket</a>
                    </div>
                </div>
            </div>
        </>
    )
};

const OngoingTickets = () => {
    return <div>Ongoing Tickets</div>;
};

const ResolvedTickets = () => {
    return <div>Resolved Tickets</div>;
};

export const TicketsList = () => {
    const [priorityOption, setPriorityOption] = useState(null);
    const [selectedSortBy, setSelectedSortBy] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [current, setCurrent] = useState('allTickets');

    const handleMenuClick = (e) => {
        setCurrent(e.key);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            border: "1px solid #E5E5E5",
            borderRadius: "4px",
            boxShadow: "none",
            cursor: "pointer",
            color: "#ce1b28",
        }),
    };

    let content;
    switch (current) {
        case 'allTickets':
            content = <AllTickets />;
            break;
        case 'new':
            content = <NewTickets />;
            break;
        case 'ongoing':
            content = <OngoingTickets />;
            break;
        case 'resolved':
            content = <ResolvedTickets />;
            break;
        default:
            content = null;
    }

    return (
        <>
            <div className="post-list-section">
                <div className="filter-row">
                    <div className="search-table-container">
                        <Input
                            placeholder="Search for Tickets"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            className="search-input-table me-2"
                            prefix={<SearchOutlined />}
                        />
                    </div>
                    <div className="select-options">
                        <Select
                            options={priorityOptions}
                            onChange={setPriorityOption}
                            placeholder="Select Priority"
                            styles={{ ...customStyles, menuPortal: document.body }}
                        />
                        <Select
                            options={sortByOptions}
                            onChange={(selected) => setSelectedSortBy(selected)}
                            placeholder="Sort By"
                            styles={{ ...customStyles, menuPortal: document.body }}
                        />
                    </div>
                </div>
                <div className="">
                    <Menu onClick={handleMenuClick} selectedKeys={[current]} mode="horizontal">
                        <Menu.Item key="allTickets" icon={<MailOutlined />}>
                            All Tickets
                        </Menu.Item>
                        <Menu.Item key="new" icon={<MailOutlined />} >
                            New
                        </Menu.Item>
                        <Menu.Item key="ongoing" icon={<MailOutlined />} >
                            On Going
                        </Menu.Item>
                        <Menu.Item key="resolved" icon={<MailOutlined />} >
                            Resolved
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            {content}
        </>
    );
};

export default TicketsList;
