import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header/Header";
import Sidebar from "../../Layout/Header/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import Instance from "../../AxiosConfig";
import { Spin } from "antd";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import "../Applicants/applicants.css"
import { showErrorAlert } from "../../globalConstant";
import { FRONTEND_BASE_URL } from "../../globalConstant";

function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const formattedDate = date.toLocaleDateString('en-US', options);

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
}

const ViewGeneralApplicants = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);


    const fetchGeneralApplicantData = async () => {
        try {
            setIsLoading(true);
            const response = await Instance.get(`/getGeneralApplicantById/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200 && response?.data?.generalApplicant) {
                console.log(response?.data?.generalApplicant);
                setFormData(response?.data?.generalApplicant);
            }
        } catch (error) {
            showErrorAlert(error?.response?.data?.message || 'An error occured');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchGeneralApplicantData();
    }, []);

    return (
        <>
            {isLoading && <div className="keyword-loader">
                <Spin size="large" className="custom-loader" />
            </div>
            }
            <Header />
            <Sidebar />
            <div className="main-wrapper">
                <div className="view-timeline">
                    <div className="title-timeline-view">
                        <h3>Applicant Details</h3>
                    </div>
                </div>

                <div className="row g-3 my-2 form-div-start">
                    <div className="col-md-6">
                        <label htmlFor="inputfirstName" className="form-label">First Name</label>
                        <input type="text" className="form-control" required id="inputfirstName"
                            value={formData?.firstName || "N/A"}
                            name='firstName'
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputlastName" className="form-label">Last Name</label>
                        <input type="text" className="form-control" required id="inputlastName"
                            value={formData?.lastName || "N/A"}
                            name='lastName'
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="inputPhone" className="form-label">Mobile Number</label>
                        <PhoneInput
                            defaultCountry="us"
                            value={formData?.mobileNumber || "N/A"}
                            inputStyle={{ width: '100%' }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="text" className="form-control" required id="email"
                            value={formData?.email || "N/A"}
                            name='email'
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="facilityOfInterest" className="form-label">Facility Of Interest</label>
                        <input type="text" className="form-control" required id="facilityOfInterest"
                            value={formData?.facilityOfInterest || "N/A"}
                            name='facilityOfInterest'
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="speciality" className="form-label">Speciality</label>
                        <input type="text" className="form-control" required id="speciality"
                            value={formData?.speciality || "N/A"}
                            name='speciality'
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="positionType" className="form-label">Position Type</label>
                        <input type="text" className="form-control" required id="positionType"
                            value={formData?.positionType || "N/A"}
                            name='positionType'
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="yearsOfExperience" className="form-label">Years of Experience</label>
                        <input type="text" className="form-control" required id="yearsOfExperience"
                            value={formData?.yearsOfExperience || "N/A"}
                            name='yearsOfExperience'
                            disabled={true}
                        />
                    </div>
                    {formData?.readyToApply && <div className="col-md-6">
                        <label htmlFor="readyToApply" className="form-label">Ready To Apply</label>
                        <input type="text" className="form-control" required id="readyToApply"
                            value={"Yes"}
                            name='readyToApply'
                            disabled={true}
                        />
                    </div>}
                    {formData?.recruiterReachOut && <div className="col-md-6">
                        <label htmlFor="recruiterReachOut" className="form-label">Recruiters Reach Out</label>
                        <input type="text" className="form-control" required id="recruiterReachOut"
                            value={getFormattedDate(formData?.recruiterReachOutDateAndTime)}
                            name='recruiterReachOut'
                            disabled={true}
                        />
                    </div>}
                    {formData?.notReadyNow && <div className="col-md-6">
                        <label htmlFor="contactInPeriod" className="form-label">Contact In Period</label>
                        <input type="text" className="form-control" required id="contactInPeriod"
                            value={formData?.contactInPeriod}
                            name='contactInPeriod'
                            disabled={true}
                        />
                    </div>}
                    <div className="col-md-6">
                        <label htmlFor="view-resume" className="form-label">View Resume</label>
                        <br />
                        {formData?.resume ?
                            <a className="view-resume-button" target="__blank" href={`https://bilkins-bucket.s3.amazonaws.com/${formData?.resume}`}>
                                Click here!
                            </a> : 'Not Available'}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="view-resume" className="form-label">View Post</label>
                        <br />
                        <a className="view-resume-button" target="_blank" rel="noreferrer" href={`${FRONTEND_BASE_URL}${formData?.postUrl}`}>
                            Click here!
                        </a>
                    </div>
                </div>
                <div className="col-4 d-flex justify-content-space-between mt-4">
                    <button className="add-benefit-button d-flex gap-2 align-content-center mx-3" onClick={() => {
                        navigate('/general-applicants');
                    }}>
                        Go Back
                    </button>
                </div>
            </div>

        </>
    );
};
export default ViewGeneralApplicants;
