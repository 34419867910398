import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Page/Auth/Login";
import DashboardPage from "./Page/Dashboard";
import PostPortalPage from "./Page/PostPortal";
import AddPost from "./Components/Post/AddPost";
import GalleryPortalPage from "./Page/GalleryPortal";
import UploadGallery from "./Components/Gallery/UploadGallery";
import Protect from "./Protect";
import ForgotPassword from "./Page/Auth/ForgotPassword";
import UserPortalPage from "./Page/UserPortal";
import JobPortalPage from "./Page/JobsPortal";
import ApplicantsPortalPage from "./Page/ApplicantsPortal";
import View from "./Components/Applicants/ViewApplication";
import AddJobs from "./Components/Jobs/AddJob";
import EditJob from "./Components/Jobs/EditJob";

import HelpPortalPage from "./Page/HelpPortal";
import SettingPortalPage from "./Page/SettingsPortal";
import EditPost from "./Components/Post/EditPost";

import NotificationPortalPage from "./Page/NotificationsPortal";
import EditProfilePortalPage from "./Page/EditProfilePortal";
import ResetPassword from "./Page/Auth/ResetPassword";
import GeneralApplicantPage from "./Page/GeneralApplicantsPortal/index.jsx";
import ViewGeneralApplicants from "./Components/GeneralApplicants/ViewGeneralApplicant.jsx";



const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/reset-password/:token"
          element={<ResetPassword />}
        />
        <Route
          path="/dashboard"
          element={<Protect Component={DashboardPage} />}
        />

        {/* POST ROUTES */}
        <Route path="/post" element={<Protect Component={PostPortalPage} />} />
        <Route path="/add-post" element={<Protect Component={AddPost} />} />
        <Route path='/edit-post/:id' element={<Protect Component={EditPost} />} />


        <Route path="/jobs" element={<Protect Component={JobPortalPage} />} />
        <Route path="/add-jobs" element={<Protect Component={AddJobs} />} />
        <Route path="/edit-job/:id" element={<Protect Component={EditJob} />} />


        <Route path="/help" element={<Protect Component={HelpPortalPage} />} />

        <Route
          path="/gallery"
          element={<Protect Component={GalleryPortalPage} />}
        />
        <Route
          path="/upload-photos"
          element={<Protect Component={UploadGallery} />}
        />
        <Route path="/user" element={<Protect Component={UserPortalPage} />} />
        <Route
          path="/applicants"
          element={<Protect Component={ApplicantsPortalPage} />}
        />
        <Route path="/view-application/:id" element={<Protect Component={View} />} />
       
       {/* General Applicants */}
       <Route path='/general-applicants' element={<Protect Component={GeneralApplicantPage} />} />
       <Route path='/view-general-applicants/:id' element={<Protect Component={ViewGeneralApplicants} />} />


        <Route path="/setting" element={<Protect Component={SettingPortalPage} />} />
        <Route path="/notification" element={<Protect Component={NotificationPortalPage} />} />
        <Route path="/edit-profile" element={<Protect Component={EditProfilePortalPage} />} />

      </Routes>
    </BrowserRouter>
  );
};

export default Router;
