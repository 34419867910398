import { createSlice } from "@reduxjs/toolkit";

const jobsSlice = createSlice({
  name: "jobsSlice",
  initialState: {
    jobs: [],
  },
  reducers: {
    addAllJobs: (state, action) => {
      state.jobs = action.payload;
    },
    editJob: (state, action) => {
      const idx = state.jobs.find(
        (data) => data._id?.toString() === action?.payload?._id?.toString()
      );
      if (idx === -1) {
        return;
      }
      state.jobs[idx] = action.payload;
    },
    deleteJob: (state, action) => {
      state.jobs = state.jobs.filter(
        (data) => data._id?.toString() !== action.payload?.toString()
      );
      
    },
  },
});

export const { addAllJobs, editJob, deleteJob } = jobsSlice.actions;

export default jobsSlice.reducer;
