import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import Sidebar from "../../Layout/Header/Sidebar";
import Header from "../../Layout/Header/Header";
import Gallery from "../../Components/Gallery/Gallery";

const GalleryPortalPage = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Sidebar />
      <Header />

      <div className="main-wrapper">
        {loading ? (
          <div className="pre-loader">
            <Spin size="large" className="custom-loader" />
          </div>
        ) : (
          <Gallery />
        )}
      </div>
    </>
  );
};

export default GalleryPortalPage;
