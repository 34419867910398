import React from 'react';
import "./editprofile.css"
import EditProfileForm from './EditProfileForm';

const EditProfile = () => {
    return (
        <>
            <EditProfileForm />
        </>
    )
}

export default EditProfile;