import React, { useEffect, useState } from "react";
import { Table, Input, DatePicker, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined, FilterOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Select from "react-select";
import Instance from "../../AxiosConfig";
import { addAllJobs, deleteJob } from "../../features/jobsSlice";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import "./jobs.css"
import { showErrorAlert, showSuccessAlert } from "../../globalConstant";

const categoryOptions = [
  { value: 'Allied Jobs', label: 'Allied Jobs' },
  { value: 'Nursing Jobs', label: 'Nursing Jobs' },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "1px solid #E5E5E5",
    borderRadius: "4px",
    boxShadow: "none",
    cursor: "pointer",
    color: "#ce1b28",
  }),
};

const JobList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [fileterParameters, setFilterParameters] = useState({});
  let jobsData = useSelector((state) => state.jobs.jobs);
  const numberFormate = new Intl.NumberFormat();

  const fetchJobData = async () => {
    try {
      setIsLoading(true);
      const url = '/getAllJobs';
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        let jobs = response?.data?.jobs;
        if (jobs) {
          jobs = jobs.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
          setAllData(jobs);
          dispatch(addAllJobs(jobs));
        }
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchJobData();
  }, []);

  const columns = [
    {
      title: "JobId",
      dataIndex: "JobId",
      key: "JobId",
      sorter: (a, b) => {
        return a?.jobId - b?.jobId
      },
      render: (text, record) => (
        <div>
          {record?.jobId ? record?.jobId : 'N/A'}
        </div>
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a?.title.localeCompare(b?.title),
      render: (text, record) => (
        <div>
          {record?.title}
        </div>
      )
    },
    {
      title: "Category",
      dataIndex: "jobCategory",
      key: "jobCategory",
      sorter: (a, b) => a?.jobCategory.localeCompare(b?.jobCategory),
      render: (text, record) => (
        <div>
          {record?.jobCategory}
        </div>
      )
    },
    {
      title: "Salary",
      dataIndex: "weeklyEarnings",
      key: "weeklyEarnings",
      sorter: (a, b) => {
        return a?.weeklyEarnings - b?.weeklyEarnings
      },
      render: (text, record) => (
        <div>
          {`${record?.weeklyEarnings ? '$' + numberFormate.format(record?.weeklyEarnings) + ' /wk' : 'N/A'}`}
        </div>
      )
    },
    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
      sorter: (a, b) => a?.jobType?.join().localeCompare(b?.jobType?.join()),
      render: (text, record) => (
        <div>
          {record?.jobType?.length > 0 ? record?.jobType?.join(' || ') : 'N/A'}
        </div>
      )
    },
    {
      title: "Shift Type",
      dataIndex: "shiftType",
      key: "shiftType",
      sorter: (a, b) => a?.shiftType?.localeCompare(b?.shiftType),
      render: (text, record) => (
        <div>
          {`${record?.shiftType ? record.shiftType + ' Shift' : 'N/A'} ${record?.shiftHours ? ", " + record.shiftHours : ''}`}
        </div>
      )
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a, b) => {
        const aL = `${a?.cities?.join()} ${a?.state}`;
        const bL = `${b?.cities?.join()} ${b?.state}`;
        return aL.localeCompare(bL);
      },
      render: (text, record) => (
        <div>
          {record?.cities?.length > 0 ? record?.cities?.join(', ') : ''}{record?.cities?.length && record?.stateCode ? ', ' : ''}{record?.stateCode ? record?.stateCode : 'N/A'}
        </div>
      )
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <EditOutlined
          key={`edit-${record._id}`}
          className="edit-button-table"
          onClick={() => navigate(`/edit-job/${record._id}`)}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <DeleteOutlined
          key={`delete-${record._id}`}
          className="delete-button-table"
          onClick={() => {
            Swal.fire({
              title: "Are you sure",
              text: "You want to Delete?",
              showCancelButton: true,
              confirmButtonColor: "#555",
              cancelButtonColor: "#ce1b28",
              confirmButtonText: "Yes, Delete!"
            }).then((result) => {
              if (result.isConfirmed) {
                handleDeleteJob(record._id)
              }
            })
          }}
        />
      ),
    },
  ];

  const handleDeleteJob = async (jobId) => {
    try {
      setIsLoading(true);
      const url = `/deleteJob/${jobId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        dispatch(deleteJob(jobId));
        let curData = allData?.filter((item) => item?._id?.toString() !== jobId?.toString());
        setAllData(curData);
        showSuccessAlert("Job deleted successfully!");
      }
    } catch (error) {
      showErrorAlert(error?.response?.data?.message || 'An error occured while deleting the job.');
    } finally{
      setIsLoading(false);
    }
  }

  const handleFilter = () => {
    let filteredData = [...allData];
    if (fileterParameters?.date) {
      filteredData = filteredData?.filter((item) => {
        let filterDate = new Date(fileterParameters?.date);
        let itemDate = new Date(item?.jobPostedDate);
        return filterDate?.getDate() === itemDate?.getDate() && filterDate?.getMonth() === itemDate?.getMonth() && filterDate?.getFullYear() === itemDate?.getFullYear();
      });
    }

    if (fileterParameters?.categories && fileterParameters?.categories.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.categories?.findIndex((d) => d.value === item?.jobCategory);
        if (exist !== -1) return 1;
        return 0;
      })
    }

    if (fileterParameters?.selectedRows && fileterParameters?.selectedRows.length !== 0) {
      filteredData = filteredData?.filter((item) => {
        let exist = fileterParameters?.selectedRows.findIndex((d) => d?.toString() === item?._id?.toString());
        if (exist !== -1) return 1;
        return 0;
      });
    }

    dispatch(addAllJobs(filteredData));
  };


  useEffect(() => {
    if (fileterParameters?.searchText === '') {
      dispatch(addAllJobs(allData));
    };
    let filteredData = allData?.filter(item => JSON.stringify(item)?.toLowerCase().includes(fileterParameters?.searchText?.toLowerCase()));
    dispatch(addAllJobs(filteredData));
  }, [fileterParameters?.searchText]);

  const handleOpenAddPostModal = () => {
    localStorage.setItem("addpost", true)
    navigate("/add-jobs")
  };

  return (
    <>
      {isLoading && <div className="keyword-loader">
        <Spin size="large" className="custom-loader" />
      </div>
      }
      <div className="main-title-all">
        <span>Jobs</span>
        <button onClick={handleOpenAddPostModal}>Add Jobs</button>
      </div>
      <div className="post-list-section">
        <div className="filter-row">
          <div className="select-options">
            <DatePicker
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['date'] = e;
                setFilterParameters(newData);
              }}
              className="custom-date-picker mx-2"
              format={'MM/DD/YYYY'}
            />

            <Select
              options={categoryOptions}
              isMulti
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['categories'] = e;
                setFilterParameters(newData);
              }}
              placeholder="All Categories"
              styles={{ ...customStyles, menuPortal: document.body }}
            />
          </div>
          <div className="search-table-container">
            <Input
              placeholder="Search..."
              value={fileterParameters?.searchText}
              onChange={(e) => {
                let newData = { ...fileterParameters };
                newData['searchText'] = e.target.value;
                setFilterParameters(newData);
              }}
              className="search-input-table me-2"
              prefix={<SearchOutlined />}
            />
            <button onClick={handleFilter}>
              <FilterOutlined /> <span>Filter</span>
            </button>
          </div>
        </div>

        {/* <div className={`filter-panel ${showFilterPanel ? "show" : ""}`}>
          <div className="filter-title">
            <span>Filter</span>
            <CloseOutlined className="close-icon" onClick={handleFilter} />
          </div>
          <hr />

          {categoryOptions.map((option) => (
            <div key={option.value} className="checkbox-item">
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                checked={selectedCategories.some(
                  (category) => category.value === option.value
                )}
                onChange={() => handleCheckboxChange(option)}
              />
              <label className="mx-2" htmlFor={option.value}>
                {option.label}
              </label>
            </div>
          ))}

          <button className="filter-button" onClick={handleFilter}>
            Filter
          </button>
        </div> */}

        <div className="table-list">
          <Table
            columns={columns}
            dataSource={jobsData}
            rowKey={(data) => data._id}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setFilterParameters(prevData => ({
                  ...prevData,
                  ['selectedRows']: selectedRowKeys
                }));
              },
            }}
            pagination={{ pageSize: 40 }}
          />
        </div>
      </div>
    </>
  );
};

export default JobList;
